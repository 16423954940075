import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    KeyboardAvoidingView,
    Platform,
    Dimensions,
} from 'react-native';
import { CheckBox, Icon } from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import tw from '../../../lib/tailwind';
import { selectHeaderBarLayout } from '../../../slices/headerBarSlice';
import { setPassword } from '../../../slices/registerUserSlice';
import BackButton from '../../../uiKit/backButton';
import Input from '../../../uiKit/input';
import NextButton from '../../../uiKit/nextButton';

export default function PasswordForm({ navigation }) {
    const dispatch = useDispatch();

    const [password, setPasswordState] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [passwordPoints, setPasswordPoints] = useState(0);
    const [passwordStrongText, setPasswordStrongText] =
        useState('Kein Password');
    const [passwordStrongColor, setPasswordStrongColor] = useState('gray-300');

    const HeaderBarLayout = useSelector(selectHeaderBarLayout);

    useEffect(() => {
        var tempPass = password.trim();
        var tempPassRepeat = repeatPassword.trim();

        var inclCapitalLetters = tempPass.match(/[A-Z]+/);
        var inclSmallLetters = tempPass.match(/[a-z]+/);
        var inclNumbers = tempPass.match(/[0-9]+/);
        var inclSpecialChar = tempPass.match(
            /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
        );
        var longEnough = tempPass.length >= 6;

        if (longEnough) {
            var temp = [
                inclCapitalLetters,
                inclSmallLetters,
                inclNumbers,
                longEnough,
                inclSpecialChar,
            ];

            var safety = temp.filter((value) => value).length * 2;
            setPasswordPoints(safety);
        } else if (tempPass.length > 0 && tempPass.length < 6) {
            setPasswordPoints(1);
        } else {
            setPasswordPoints(0);
        }

        if (passwordPoints > 4) {
            if (tempPass === tempPassRepeat) {
                setButtonDisabled(false);
            } else {
                setButtonDisabled(true);
            }
        } else {
            setButtonDisabled(true);
        }
    }, [password, repeatPassword, passwordPoints]);

    useEffect(() => {
        switch (passwordPoints) {
            case 0:
                setPasswordStrongText('Kein Passwort');
                setPasswordStrongColor('gray-300');
                break;
            case 1:
                setPasswordStrongText('Passwort zu kurz');
                setPasswordStrongColor('gray-300');
                break;
            case 2:
                setPasswordStrongText('Sehr Schwach!');
                setPasswordStrongColor('red-400');
                break;

            case 3:
            case 4:
                setPasswordStrongText('Schwach!');
                setPasswordStrongColor('red-200');
                break;

            case 5:
            case 6:
                setPasswordStrongText('Stark!');
                setPasswordStrongColor('yellow-400');
                break;

            case 7:
            case 8:
            case 9:
                setPasswordStrongText('Sehr Stark!');
                setPasswordStrongColor('green-200');
                break;

            case 10:
                setPasswordStrongText('Super Stark!');
                setPasswordStrongColor('green-400');
                break;

            default:
                break;
        }
    }, [passwordPoints]);

    function saveEntry() {
        dispatch(setPassword(password));
        navigation.push('legalForm');
    }

    return (
        <View
            style={tw.style(
                'w-full h-full flex justify-between items-center px-6'
            )}
        >
            <View style={tw.style('w-full')}>
                <Text
                    style={tw.style(
                        'text-xl font-bold text-gray-700 text-center my-7'
                    )}
                >
                    Schütze dein Konto mit einem starke Passwort.
                </Text>
                <Input
                    placeholder="Passwort"
                    width="full"
                    onChangeText={(text) => setPasswordState(text)}
                    value={password}
                    secureTextEntry={true}
                />
                <Input
                    placeholder="Passwort wiederholen"
                    width="full"
                    onChangeText={(text) => setRepeatPassword(text)}
                    value={repeatPassword}
                    secureTextEntry={true}
                />
                <Text
                    style={tw.style(
                        'text-xs text-gray-400 font-light mt--2 mb-4'
                    )}
                >
                    (min. 6 Zeichen, Groß- und Kleinbuchstaben, eine Ziffer, ein
                    Sonderzeichen)
                </Text>
                <View
                    style={tw.style(
                        'w-1/2 flex items-center justify-center p-3 rounded-xl bg-' +
                            passwordStrongColor
                    )}
                >
                    <Text>{passwordStrongText}</Text>
                </View>
            </View>
            <KeyboardAvoidingView
                behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                style={tw.style('mb-4')}
                keyboardVerticalOffset={HeaderBarLayout.height + 14}
            >
                <View style={tw.style('flex flex-row justify-center w-full')}>
                    <BackButton onPress={() => navigation.goBack()} />
                    <NextButton
                        onPress={() => saveEntry()}
                        label="Weiter"
                        showIcon
                        disabled={buttonDisabled}
                    />
                </View>
            </KeyboardAvoidingView>
        </View>
    );
}
