import {
    View,
    Text,
    TouchableWithoutFeedback,
    Keyboard,
    Platform,
} from 'react-native';
import React from 'react';
import UploadDriverLicense from './uploadDriverLicense';
import LicensePlate from './licensePlate';
import VehicleType from './vehicleType';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HeaderBar from '../../../uiKit/headerBar';
import tw from '../../../lib/tailwind';

export default function RegisterUbring() {
    const Stack = createNativeStackNavigator();
    return (
        <TouchableWithoutFeedback
            onPress={() => {
                if (Platform.OS != 'web') {
                    Keyboard.dismiss();
                }
            }}
        >
            <View style={tw.style('flex-1')}>
                <HeaderBar siteTitle="U-register" />
                <Stack.Navigator
                    screenOptions={{ headerShown: false }}
                    initialRouteName="uploadDriverLicense"
                >
                    <Stack.Screen
                        name="uploadDriverLicense"
                        component={UploadDriverLicense}
                    />
                    <Stack.Screen
                        name="licensePlate"
                        component={LicensePlate}
                    />
                    <Stack.Screen name="vehicleType" component={VehicleType} />
                </Stack.Navigator>
            </View>
        </TouchableWithoutFeedback>
    );
}
