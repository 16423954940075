import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    KeyboardAvoidingView,
    Platform,
    Dimensions,
} from 'react-native';
import { CheckBox, Icon } from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import tw from '../../../lib/tailwind';
import { selectHeaderBarLayout } from '../../../slices/headerBarSlice';
import { setPhoneNumber } from '../../../slices/registerUserSlice';
import BackButton from '../../../uiKit/backButton';
import Input from '../../../uiKit/input';
import NextButton from '../../../uiKit/nextButton';

export default function PhoneNumberForm({ navigation }) {
    const dispatch = useDispatch();

    const [number, setNumber] = useState('');
    const [buttonDisabeld, setButtonDisabled] = useState(true);

    const HeaderBarLayout = useSelector(selectHeaderBarLayout);

    useEffect(() => {
        if (number !== '') {
            var phone = number
                .replace(' ', '')
                .replace('+49', '0')
                .replace('(', '')
                .replace(')', '')
                .replace('-', '')
                .replace('/', '');

            if (phone.match(/^[0][0-9]*$/) && phone.length > 6) {
                setButtonDisabled(false);
            } else {
                setButtonDisabled(true);
            }
        }
    }, [number]);

    function saveEntry() {
        dispatch(
            setPhoneNumber(
                number
                    .replace(' ', '')
                    .replace('+49', '0')
                    .replace('(', '')
                    .replace(')', '')
                    .replace('-', '')
                    .replace('/', '')
            )
        );
        navigation.push('emailForm');
    }

    return (
        <View
            style={tw.style(
                'w-full h-full flex justify-between items-center px-6'
            )}
        >
            <View style={tw.style('w-full')}>
                <Text
                    style={tw.style(
                        'text-xl font-bold text-gray-700 text-center my-7'
                    )}
                >
                    Bitte gib deine Handynummer ein.
                </Text>
                <Input
                    placeholder="Mobilnummer"
                    width="full"
                    onChangeText={(text) => setNumber(text)}
                    value={number}
                    type="phone"
                    keyboardType="phone-pad"
                />
                <Text style={tw.style('mt--2 font-thin text-sm text-gray-500')}>
                    Deine Telefonnummer fängt mit 0 an.
                </Text>
            </View>
            <KeyboardAvoidingView
                behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                style={tw.style('mb-4')}
                keyboardVerticalOffset={HeaderBarLayout.height + 14}
            >
                <View style={tw.style('flex flex-row justify-center w-full')}>
                    <BackButton onPress={() => navigation.goBack()} />
                    <NextButton
                        onPress={() => saveEntry()}
                        label="Weiter"
                        showIcon
                        disabled={buttonDisabeld}
                    />
                </View>
            </KeyboardAvoidingView>
        </View>
    );
}
