import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    KeyboardAvoidingView,
    Platform,
    Dimensions,
} from 'react-native';
import { CheckBox, Icon } from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import tw from '../../../lib/tailwind';
import { selectHeaderBarLayout } from '../../../slices/headerBarSlice';
import { setNameAndCompany } from '../../../slices/registerUserSlice';
import BackButton from '../../../uiKit/backButton';
import Input from '../../../uiKit/input';
import NextButton from '../../../uiKit/nextButton';

export default function NameForm({ navigation }) {
    const dispatch = useDispatch();

    const [lastname, setLastname] = useState('');
    const [firstname, setFirstname] = useState('');
    const [username, setUsername] = useState('');
    const [isCompany, setIsCompany] = useState(false);
    const [companyName, setCompanyName] = useState('');

    const HeaderBarLayout = useSelector(selectHeaderBarLayout);

    const [buttonDisabled, setButtonDisabled] = useState(true);

    useEffect(() => {
        if (
            firstname.length > 2 &&
            lastname.length > 2 &&
            username.length >= 6
        ) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [lastname, firstname, username]);

    function updateCompanyName(newValue) {
        if (newValue !== '') {
            setIsCompany(true);
        } else {
            setIsCompany(false);
        }

        setCompanyName(newValue);
    }

    function saveEntry() {
        if (!buttonDisabled) {
            dispatch(
                setNameAndCompany({
                    firstname,
                    lastname,
                    isCompany,
                    companyName,
                    username,
                })
            );
        }
        navigation.push('addressForm');
    }

    return (
        <View
            style={tw.style(
                'w-full flex-1 flex justify-between items-center px-6'
            )}
        >
            <View style={tw.style('w-full')}>
                <Text
                    style={tw.style(
                        'text-xl font-bold text-gray-700 text-center my-7'
                    )}
                >
                    Bitte gib deinen Namen ein.
                </Text>
                <Input
                    placeholder="Vorname"
                    onChangeText={(event) => setFirstname(event)}
                    value={firstname}
                    width="full"
                />
                <Input
                    placeholder="Nachname"
                    onChangeText={(event) => setLastname(event)}
                    value={lastname}
                    width="full"
                />
                <Input
                    placeholder="Usernamen"
                    onChangeText={(event) => setUsername(event)}
                    value={username}
                    width="full"
                />
                <View
                    style={tw.style(
                        'w-full flex flex-row justify-between items-center'
                    )}
                >
                    <View
                        style={tw.style(
                            'flex flex-row justify-between items-center'
                        )}
                    >
                        <Text style={tw.style('font-semibold text-gray-800')}>
                            Firma?
                        </Text>
                        <CheckBox
                            uncheckedIcon={
                                <Icon
                                    name="radio-button-unchecked"
                                    color={tw.color('primary')}
                                />
                            }
                            checkedIcon={
                                <Icon
                                    name="radio-button-checked"
                                    color={tw.color('primary')}
                                />
                            }
                            checked={isCompany}
                            center
                            onPress={() => setIsCompany(!isCompany)}
                        />
                    </View>
                    <Input
                        placeholder="Firmenname"
                        onChangeText={(event) => updateCompanyName(event)}
                        value={companyName}
                        width="2/3"
                    />
                </View>
            </View>
            <KeyboardAvoidingView
                behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                style={tw.style('h-auto flex justify-end')}
                keyboardVerticalOffset={HeaderBarLayout.height + 14}
            >
                <View style={tw.style('flex flex-row justify-center w-full')}>
                    <BackButton onPress={() => navigation.goBack()} />
                    <NextButton
                        onPress={() => saveEntry()}
                        label="Weiter"
                        showIcon
                        disabled={buttonDisabled}
                    />
                </View>
            </KeyboardAvoidingView>
        </View>
    );
}
