import { View, Text } from 'react-native';
import React from 'react';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';
import tw from '../../../lib/tailwind';

export default function SenderDetails({ navigation, route }) {
    const params = route.params;

    function handOverPackage() {
        navigation.push('confirmHandOver', {
            requestID: params.data.data.accepted_request_id,

            action: 'handOver',
            type: 'sender_handed_over',
        });
    }
    return (
        <View style={tw.style('p-6')}>
            <View style={tw.style('flex flex-row justify-between')}>
                <BackButton onPress={() => navigation.goBack()} />
                <NextButton
                    onPress={() => handOverPackage()}
                    label="Packet abgeben"
                />
            </View>
        </View>
    );
}
