import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import {
    View,
    Text,
    TouchableWithoutFeedback,
    Keyboard,
    Platform,
} from 'react-native';
import tw from '../../../lib/tailwind';
import HeaderBar from '../../../uiKit/headerBar';
import AddressForm from './addressForm';
import EmailForm from './emailForm';
import LegalForm from './legalForm';
import NameForm from './nameForm';
import PasswordForm from './passwordForm';
import PhoneNumberForm from './phonenumberForm';
import ValidateCode from './validateCode';

export default function RegisterIndex() {
    const Stack = createNativeStackNavigator();

    return (
        <TouchableWithoutFeedback
            onPress={() => {
                if (Platform.OS != 'web') {
                    Keyboard.dismiss();
                }
            }}
        >
            <View style={tw.style('flex-1')}>
                <HeaderBar siteTitle="U-register" />
                <Stack.Navigator
                    screenOptions={{ headerShown: false }}
                    initialRouteName="nameform"
                >
                    <Stack.Screen name="nameForm" component={NameForm} />
                    <Stack.Screen name="addressForm" component={AddressForm} />
                    <Stack.Screen
                        name="passwordForm"
                        component={PasswordForm}
                    />
                    <Stack.Screen name="emailForm" component={EmailForm} />
                    <Stack.Screen name="legalForm" component={LegalForm} />
                    <Stack.Screen
                        name="validateCode"
                        component={ValidateCode}
                    />
                    <Stack.Screen
                        name="phoneNumberForm"
                        component={PhoneNumberForm}
                    />
                </Stack.Navigator>
            </View>
        </TouchableWithoutFeedback>
    );
}
