import { View, Text, Image } from 'react-native';
import React, { useState } from 'react';
import tw from '../../../lib/tailwind';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';

import Empfangen from '../../../assets/images/illustrations/empfangen.png';
import Übergeben from '../../../assets/images/illustrations/u-bring-delivery-absender_2.png';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectToken } from '../../../slices/userSlice';

export default function ConfirmHandOver({ navigation, route }) {
    const params = route.params;

    const [loading, setLoading] = useState(false);

    const type = params.type;
    const requestID = params.requestID;

    const token = useSelector(selectToken);

    function buttonHandler() {
        setLoading(true);
        axios
            .post(
                process.env.APIURL + 'shipment/request',
                {
                    requestID: parseInt(requestID),
                    type,
                },
                {
                    headers: { 'Authorization': `Bearer ${token}` },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    navigation.replace('authenticatedContainer', {
                        screen: 'uMission',
                        merge: true,
                    });
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e.response);
                setLoading(false);
            });
    }

    if (params.action === 'recieve') {
        return (
            <View
                style={tw.style('flex-1 flex justify-evenly items-center p-6')}
            >
                <Text style={tw.style('text-xl font-bold text-gray-800')}>
                    Quittieren der Übergabe
                </Text>
                <Image
                    source={Empfangen}
                    style={tw.style('w-5/5 h-75')}
                    resizeMode="contain"
                />
                <Text style={tw.style('text-lg font-bold text-gray-800')}>
                    Bestätige die Übergabe des Pakets
                </Text>
                <View style={tw.style('flex flex-row justify-between')}>
                    <BackButton onPress={() => navigation.goBack()} />
                    <NextButton
                        onPress={() => buttonHandler()}
                        label="Bestätigen"
                        loading={loading}
                        disabled={loading}
                        showIcon={loading}
                    />
                </View>
            </View>
        );
    } else if (params.action === 'handOver') {
        return (
            <View
                style={tw.style('flex-1 flex justify-evenly items-center p-6')}
            >
                <Text style={tw.style('text-xl font-bold text-gray-800')}>
                    Quittieren der Übergabe
                </Text>
                <Image
                    source={Übergeben}
                    style={tw.style('w-5/5 h-75')}
                    resizeMode="contain"
                />
                <Text style={tw.style('text-lg font-bold text-gray-800')}>
                    Ich habe die Sendung abgegeben
                </Text>
                <View style={tw.style('flex flex-row justify-between')}>
                    <BackButton onPress={() => navigation.goBack()} />
                    <NextButton
                        onPress={() => buttonHandler()}
                        label="Bestätigen"
                        loading={loading}
                        disabled={loading}
                        showIcon={loading}
                    />
                </View>
            </View>
        );
    } else {
        return (
            <View>
                <Text>Fehler</Text>
            </View>
        );
    }
}
