import React, { useState } from 'react';
import { View, Text, Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import tw from '../../lib/tailwind';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import NameForm from './register/nameForm';
import LoginScreen from './loginScreen';
import RegisterIndex from './register';
import RegisterUbring from './register_ubring';

export default function LoginContainer({ navigation }) {
    const Stack = createNativeStackNavigator();

    return (
        <SafeAreaView
            edges={['left', 'right', 'bottom']}
            style={tw.style('flex-1')}
        >
            <Stack.Navigator
                initialRouteName="login"
                screenOptions={{ headerShown: false }}
            >
                <Stack.Screen name="login" component={LoginScreen} />
                <Stack.Screen name="register" component={RegisterIndex} />
                <Stack.Screen
                    name="registerUbring"
                    component={RegisterUbring}
                />
            </Stack.Navigator>
        </SafeAreaView>
    );
}
