import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { View, Text, Image, Platform } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import tw from '../lib/tailwind';
import Logo from '../assets/images/logo/small.png';
import { useDispatch } from 'react-redux';
import { setHeaderbarLayout } from '../slices/headerBarSlice';

export default function HeaderBar({ siteTitle }) {
    const dispatch = useDispatch();

    return (
        <View
            style={tw.style('w-full h-1/8 bg-white shadow-lg z-10')}
            onLayout={(event) =>
                dispatch(setHeaderbarLayout(event.nativeEvent.layout))
            }
        >
            {Platform.OS != 'web' && (
                <View
                    style={{
                        height: StatusBar.currentHeight || 32,
                        width: '100%',
                    }}
                />
            )}
            <View
                style={tw.style(
                    'w-full flex-1  flex flex-row justify-center items-center ' +
                        (Platform.OS === 'web' ? '' : 'pt-3')
                )}
            >
                <Image
                    source={Logo}
                    style={tw.style('h-2/3 w-1/6')}
                    resizeMode="contain"
                />
                <Text style={tw.style('text-3xl font-bold text-primary-dark')}>
                    {siteTitle}
                </Text>
            </View>
        </View>
    );
}
