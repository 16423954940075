import React, { useEffect, useState } from 'react';
import { View, Text, Image, TouchableOpacity, Platform } from 'react-native';
import tw from '../lib/tailwind';

import MapView, {
    Callout,
    Circle,
    Marker,
    PROVIDER_GOOGLE,
} from 'react-native-maps';
import * as Location from 'expo-location';

import MarkerBringer from '../assets/images/icons/map/Bringer_MapIcon.png';
import MarkerSender from '../assets/images/icons/map/Sender_MapIcon.png';
import { useSelector } from 'react-redux';
import {
    selectLoggedInState,
    selectToken,
    selectUserInfo,
} from '../slices/userSlice';
import HeaderBar from '../uiKit/headerBar';
import StartMenuButton from '../uiKit/startMenuButton';
import axios from 'axios';
import { useIsFocused } from '@react-navigation/native';

export default function StartScreen({ navigation }) {
    const [currentLocation, setCurrentLocation] = useState({});

    const [packages, setPackages] = useState([]);

    const isLoggedIn = useSelector(selectLoggedInState);
    const userInfo = useSelector(selectUserInfo);
    const savedToken = useSelector(selectToken);

    const [token, setToken] = useState('');

    const currentlyFocused = useIsFocused();

    useEffect(async () => {
        if (savedToken) {
            setToken(savedToken);
        } else {
            var res = await axios
                .post(
                    process.env.APIURL + 'token',
                    {
                        username: 'demo@demo.de',
                        password: 'ifrOGqpn3EiV5C*$lfDNv0%j',
                    },
                    {
                        'headers': {
                            'content-type': 'application/json',
                        },
                    }
                )
                .catch((e) => {
                    console.log(e.response);
                });

            if (res?.data) {
                const data = res.data.data;
                setToken(data.accessToken);
            }
        }

        if (currentLocation.coords && token !== '') {
            console.log(token);
            axios
                .post(
                    process.env.APIURL + 'shipments/radius',
                    {
                        latitude: currentLocation.coords.latitude,
                        longitude: currentLocation.coords.longitude,
                    },
                    {
                        headers: { 'Authorization': `Bearer ${token}` },
                    }
                )
                .then((res) => {
                    if (res?.data) {
                        setPackages(res.data);
                    }
                })
                .catch((e) => console.log(e.response));
        }
    }, [currentlyFocused, savedToken, currentLocation]);

    const [mapViewCurrentRegion, setMapViewCurrentRegion] = useState({
        latitude: 51.37052,
        longitude: 10.52277,
        latitudeDelta: 8,
        longitudeDelta: 8,
    });

    useEffect(() => {
        (async () => {
            await Location.enableNetworkProviderAsync();

            let { statusLocation } =
                await Location.requestForegroundPermissionsAsync();

            if (statusLocation === 'denied') {
                console.log('Location was denied');
                return;
            }

            let lastKnownLocation = await Location.getLastKnownPositionAsync(
                {}
            );

            if (lastKnownLocation?.coords) {
                setCurrentLocation(lastKnownLocation);
                setMapViewCurrentRegion({
                    latitude: lastKnownLocation.coords.latitude,
                    longitude: lastKnownLocation.coords.longitude,
                    latitudeDelta: 0.1,
                    longitudeDelta: 0.1,
                });
            }

            let location = await Location.getCurrentPositionAsync({
                accuracy: Location.Accuracy.Highest,
            });

            if (location.coords) {
                setCurrentLocation(location);
                setMapViewCurrentRegion({
                    latitude: location.coords.latitude,
                    longitude: location.coords.longitude,
                    latitudeDelta: 0.1,
                    longitudeDelta: 0.1,
                });
            } else {
                console.log('Location not found');
            }
        })();
    }, []);

    function navigateWhenAuthenticated(screen) {
        if (isLoggedIn) {
            navigation.push('authenticatedContainer', {
                screen,
            });
        } else {
            navigation.push('loginContainer', {
                screen: 'login',
                params: {
                    screen,
                },
            });
        }
    }

    return (
        <View style={tw.style('flex-1')}>
            <HeaderBar siteTitle="U-do" />

            <View style={tw.style('flex-1 items-center bg-background')}>
                <MapView
                    initialRegion={{
                        latitude: 51.37052,
                        longitude: 10.52277,
                        latitudeDelta: 5,
                        longitudeDelta: 5,
                    }}
                    loadingIndicatorColor={tw.color('primary')}
                    loadingBackgroundColor={tw.color('gray-300')}
                    region={mapViewCurrentRegion}
                    provider={PROVIDER_GOOGLE}
                    mapType="standard"
                    style={tw.style('w-full h-2/5')}
                >
                    <MapView.Marker
                        coordinate={{
                            latitude: currentLocation.coords?.latitude || 0,
                            longitude: currentLocation.coords?.longitude || 0,
                        }}
                        icon={MarkerBringer}
                        style={{ zIndex: 10 }}
                    >
                        <Image source={MarkerBringer} resizeMode="contain" />
                    </MapView.Marker>
                    {packages.map((data, index) => {
                        var formatedPrice = data.price / 100;

                        formatedPrice =
                            formatedPrice.toFixed(2).replace('.', ',') + ' €';

                        return (
                            <MapView.Marker
                                coordinate={{
                                    latitude: parseFloat(data.latitude),
                                    longitude: parseFloat(data.longitude),
                                }}
                                key={index}
                                icon={MarkerSender}
                                style={{ zIndex: 1000 }}
                            >
                                <View
                                    style={tw.style(
                                        'flex justify-center items-center'
                                    )}
                                >
                                    <View
                                        style={tw.style(
                                            'bg-white rounded-lg p-3 shadow-md mb-1.5'
                                        )}
                                    >
                                        <Text>{formatedPrice}</Text>
                                    </View>
                                    <Image
                                        source={MarkerSender}
                                        resizeMode="contain"
                                    />
                                </View>
                            </MapView.Marker>
                        );
                    })}
                </MapView>
                <Text style={{ opacity: 0 }}>
                    Alle Aufträge in der näheren Umgebung
                </Text>
                <View style={tw.style('w-2/3')}>
                    <StartMenuButton
                        title="Bringen"
                        color="primary"
                        onPress={() => navigateWhenAuthenticated('uBring')}
                    />
                    <StartMenuButton
                        title="Senden"
                        color="yellow"
                        onPress={() => navigateWhenAuthenticated('uSend')}
                    />
                    <StartMenuButton
                        title="Aufträge"
                        color="gray"
                        onPress={() => navigateWhenAuthenticated('uMission')}
                    />
                </View>
            </View>
        </View>
    );
}
