import {
    View,
    Text,
    TouchableWithoutFeedback,
    ScrollView,
    KeyboardAvoidingView,
} from 'react-native';
import React, { useEffect, useState } from 'react';
import tw from '../../../lib/tailwind';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../../slices/userSlice';
import { CheckBox, Icon } from 'react-native-elements';
import Input from '../../../uiKit/input';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import uuid from 'uuid';

export default function SetOrigin({ navigation }) {
    const [buttonDeactivated, setButtonDeativated] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);

    const [useOwnAddress, setUseOwnAddress] = useState(false);
    const [useNewAddress, setUseNewAddress] = useState(true);

    const titleStyle = tw.style('font-semibold text-lg mb-2');

    const userInfo = useSelector(selectUserInfo);

    const [location, setLocation] = useState({
        streetName: '',
        houseNumber: '',
        postal: '',
        city: '',
        lat: 0.0,
        long: 0.0,
        state: '',
        placeID: null,
    });

    function updateSelectedOrigin(selector) {
        switch (selector) {
            case 'ownAddress':
                setLocation({
                    streetName: userInfo.address.street,
                    houseNumber: userInfo.address.number,
                    postal: userInfo.address.postal,
                    city: userInfo.address.city,
                    lat: userInfo.address.latitude,
                    long: userInfo.address.longitude,
                    state: userInfo.address.state,
                    placeID: userInfo.address.placeID,
                });

                setUseOwnAddress(true);
                setUseNewAddress(false);
                break;

            case 'newAddress':
                setUseNewAddress(true);
                setUseOwnAddress(false);
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        if (useOwnAddress) {
            setButtonDeativated(false);
        } else {
            setButtonDeativated(true);
            const { city, houseNumber, lat, long, postal, streetName, state } =
                location;
            if (
                city &&
                houseNumber &&
                lat &&
                long &&
                postal &&
                streetName &&
                state
            ) {
                setButtonDeativated(false);
            } else {
                setButtonDeativated(true);
            }
        }
    }, [useOwnAddress, useNewAddress, location]);

    function saveOrigin() {
        navigation.navigate({
            name: 'addRoute',
            params: {
                originLocation: {
                    postal: location.postal,
                    street: location.streetName,
                    number: location.houseNumber,
                    city: location.city,
                    state: location.state,
                    latitude: location.lat,
                    longitude: location.long,
                    placeID: location.placeID,
                    shortString:
                        location.streetName + ' ' + location.houseNumber,
                    longString:
                        location.streetName +
                        ' ' +
                        location.houseNumber +
                        ', ' +
                        location.postal +
                        ' ' +
                        location.city +
                        ', Deutschland',
                },
            },
            merge: true,
        });
    }

    return (
        <View style={tw.style('px-6 pt-6 flex-1 justify-between')}>
            <View style={tw.style('')}>
                <View style={tw.style('flex ')}>
                    <View
                        style={tw.style(
                            'flex-row justify-between items-center'
                        )}
                    >
                        <TouchableWithoutFeedback
                            onPress={() => updateSelectedOrigin('newAddress')}
                        >
                            <Text style={titleStyle}>
                                Neue Addresse erstellen
                            </Text>
                        </TouchableWithoutFeedback>
                        <CheckBox
                            checked={useNewAddress}
                            onPress={() => updateSelectedOrigin('newAddress')}
                            containerStyle={tw.style(
                                'bg-background shadow-none border-0 bg-opacity-0'
                            )}
                            checkedIcon={
                                <Icon
                                    name="radio-button-checked"
                                    color={tw.color('primary')}
                                />
                            }
                            uncheckedIcon={
                                <Icon
                                    name="radio-button-unchecked"
                                    color={tw.color('primary')}
                                />
                            }
                        />
                    </View>

                    <GooglePlacesAutocomplete
                        styles={{
                            'textInput': tw.style(''),
                            'textInputContainer': tw.style(
                                'bg-white rounded-xl shadow-md py-4 px-5'
                            ),
                            'listView': tw.style('mt-1 rounded-xl mb-2'),
                            'row': tw.style('bg-white p-3 px-2 '),
                            'separator': tw.style('bg-gray-300 h-.1'),
                        }}
                        placeholder="Adresse"
                        nearbyPlacesAPI="GooglePlacesSearch"
                        debounce={400}
                        enablePoweredByContainer={false}
                        query={{
                            key: process.env.GOOGLE_API_KEY,
                            language: 'de',
                            components: 'country:de',
                        }}
                        minLength={2}
                        fetchDetails={true}
                        returnKeyType="Suchen"
                        suppressDefaultStyles
                        onPress={(data, details = null) => {
                            var houseNumber = 0;
                            var streetName = '';
                            var postal = 0;
                            var city = '';
                            var state = '';

                            details.address_components.map(
                                ({ types, long_name }) => {
                                    switch (types[0]) {
                                        case 'street_number':
                                            houseNumber = long_name;
                                            break;

                                        case 'route':
                                            streetName = long_name;
                                            break;

                                        case 'administrative_area_level_1':
                                            state = long_name;
                                            break;

                                        case 'postal_code':
                                            postal = long_name;
                                            break;

                                        case 'locality':
                                            city = long_name;
                                            break;

                                        default:
                                            break;
                                    }
                                }
                            );

                            setLocation({
                                streetName: streetName,
                                houseNumber: houseNumber,
                                postal: postal,
                                city: city,
                                lat: details.geometry.location.lat,
                                long: details.geometry.location.lng,
                                state: state,
                                placeID: uuid.v4(),
                            });
                        }}
                    />
                </View>
                {userInfo.address.longString ? (
                    <>
                        <View
                            style={tw.style(
                                'h-0 flex-1 mx-12 my-6 border border-gray-200'
                            )}
                        />
                        <Text style={titleStyle}>Deine Adresse nutzen</Text>
                        <View
                            style={tw.style(
                                'flex flex-row justify-between items-center'
                            )}
                        >
                            <TouchableWithoutFeedback
                                onPress={() =>
                                    updateSelectedOrigin('ownAddress')
                                }
                            >
                                <View>
                                    <Text>{userInfo.address.shortString}</Text>
                                    <Text>
                                        {userInfo.address.postal +
                                            ' ' +
                                            userInfo.address.city}
                                    </Text>
                                    <Text>{userInfo.address.state}</Text>
                                </View>
                            </TouchableWithoutFeedback>
                            <CheckBox
                                checked={useOwnAddress}
                                onPress={() =>
                                    updateSelectedOrigin('ownAddress')
                                }
                                containerStyle={tw.style(
                                    'bg-background shadow-none border-0 bg-opacity-0'
                                )}
                                checkedIcon={
                                    <Icon
                                        name="radio-button-checked"
                                        color={tw.color('primary')}
                                    />
                                }
                                uncheckedIcon={
                                    <Icon
                                        name="radio-button-unchecked"
                                        color={tw.color('primary')}
                                    />
                                }
                            />
                        </View>
                    </>
                ) : (
                    <></>
                )}
            </View>
            <View
                style={tw.style(
                    'w-full flex flex-row justify-between mb-3 mt-10'
                )}
            >
                <BackButton onPress={() => navigation.goBack()} />
                <NextButton
                    onPress={() => saveOrigin()}
                    label="Speichern"
                    disabled={buttonDeactivated}
                    loading={buttonLoading}
                    showIcon={buttonLoading}
                />
            </View>
        </View>
    );
}
