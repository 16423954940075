import { View, Text } from 'react-native';
import React from 'react';

export default function EditProfile() {
    return (
        <View>
            <Text>EditProfile</Text>
        </View>
    );
}
