import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import React, { useEffect } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import Home from './home';
import USend from './u-send';
import { useSelector } from 'react-redux';
import tw from '../../lib/tailwind';
import {
    selectLoggedInState,
    selectToken,
    selectUserInfo,
} from '../../slices/userSlice';
import { Avatar, Icon } from 'react-native-elements';
import TabBar from '../../uiKit/tabBar';
import IndexUMission from './u-mission';
import UBringIndex from './u-bring';
import IndexUandMe from './uandme';

export default function Index({ navigation }) {
    const Tab = createBottomTabNavigator();

    const userInfo = useSelector(selectUserInfo);
    const loggedIn = useSelector(selectLoggedInState);
    const token = useSelector(selectToken);

    useEffect(() => {
        if (!loggedIn || token === null || userInfo === null) {
            dispatch(setUserInformation(null));
            dispatch(setToken());
            navigation.navigate('start', { merge: true });
        }
    }, [userInfo, loggedIn, token]);

    return (
        <Tab.Navigator
            screenOptions={{
                headerStyle: {
                    backgroundColor: tw.color('white'),
                },
                headerTitleStyle: {
                    color: tw.color('primary-dark'),
                    fontSize: 24,
                },
                headerLeft: () => (
                    <TouchableOpacity
                        style={tw.style('px-2')}
                        onPress={() => navigation.goBack()}
                    >
                        <Icon
                            name="chevron-left"
                            size={32}
                            color={tw.color('primary-dark')}
                        />
                    </TouchableOpacity>
                ),
                headerRight: () => (
                    <TouchableOpacity style={tw.style('px-2')}>
                        <Avatar
                            rounded
                            size={32}
                            source={{
                                uri:
                                    userInfo.avatarSrc !== ''
                                        ? userInfo.avatarSrc
                                        : 'https://api.multiavatar.com/' +
                                          userInfo.email +
                                          '.png',
                            }}
                        />
                    </TouchableOpacity>
                ),
                tabBarActiveTintColor: tw.color('primary'),
                tabBarInactiveTintColor: tw.color('gray-300'),
                tabBarHideOnKeyboard: true,
            }}
            tabBar={(props) => <TabBar {...props} />}
            detachInactiveScreens={true}
            backBehavior={'none'}
        >
            <Tab.Screen
                name="Home"
                component={Home}
                options={{ title: 'Home' }}
            />
            <Tab.Screen
                name="uSend"
                component={USend}
                options={{ title: 'U-send' }}
            />
            <Tab.Screen
                name="uBring"
                component={UBringIndex}
                options={{ title: 'U-bring' }}
            />
            <Tab.Screen
                name="uMission"
                component={IndexUMission}
                options={{ title: 'U-mission' }}
            />
            <Tab.Screen
                name="uAndMe"
                component={IndexUandMe}
                options={{
                    title: 'U-andme',
                }}
            />
        </Tab.Navigator>
    );
}
