import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import tw from '../../lib/tailwind';
import LogoWOSubline from '../../assets/images/logo/no_subline.png';
import Illustration1 from '../../assets/images/illustrations/u-bring-delivery-absender_1.png';
import { Icon } from 'react-native-elements';

export default function Stage1FirstOpen({ navigation }) {
    return (
        <SafeAreaView
            edges={['left', 'top', 'right']}
            style={tw.style('w-full h-full bg-white')}
        >
            <View
                style={tw.style(
                    'flex justify-center items-center w-full h-9/10 bg-white'
                )}
            >
                <Image
                    style={tw.style('h-1/10')}
                    width={'50%'}
                    resizeMode="contain"
                    source={LogoWOSubline}
                />
                <Image
                    style={tw.style('m-10 w-8/10 h-4/10 ')}
                    height={'40%'}
                    width={'80%'}
                    resizeMode="contain"
                    source={Illustration1}
                />
                <View style={tw.style('w-4/5')}>
                    <Text
                        style={tw.style(
                            'text-3xl font-bold text-gray-700 mb-5 text-center'
                        )}
                    >
                        Willkommen bei U-bring
                    </Text>
                    <Text style={tw.style('text-center')}>
                        Melde Dich an und werde als Ubi Teil der ersten
                        nachhaltigen Transport Community in Deutschland
                    </Text>
                </View>
            </View>
            <View
                style={tw.style(
                    'flex flex-row justify-between w-full h-1/10 px-10'
                )}
            >
                <TouchableOpacity
                    style={tw.style('w-1/3 h-10 flex justify-center')}
                    onPress={() => navigation.replace('start')}
                >
                    <Text style={tw.style('text-lg text-gray-600')}>Skip</Text>
                </TouchableOpacity>
                <View
                    style={tw.style(
                        'w-1/3 h-10 flex flex-row justify-evenly items-center'
                    )}
                >
                    <View style={tw.style('bg-primary h-3 w-3 rounded-full')} />
                    <View
                        style={tw.style('bg-gray-200 h-3 w-3 rounded-full')}
                    />
                    <View
                        style={tw.style('bg-gray-200 h-3 w-3 rounded-full')}
                    />
                </View>
                <TouchableOpacity
                    style={tw.style(
                        'w-1/3 h-10 flex flex-row justify-end items-center'
                    )}
                    onPress={() => navigation.navigate('stage2')}
                >
                    <Icon
                        name="arrow-forward"
                        size={28}
                        color={tw.color('primary')}
                    />
                </TouchableOpacity>
            </View>
        </SafeAreaView>
    );
}
