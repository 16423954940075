import { View, Text } from 'react-native';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import MapView from 'react-native-maps';
import tw from '../../../lib/tailwind';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';
import { useSelector } from 'react-redux';
import { selectToken, selectUserInfo } from '../../../slices/userSlice';
import axios from 'axios';

export default function LiveTracking({ navigation, route }) {
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    const [buttonText, setButtonText] = useState('Abgeben');

    const token = useSelector(selectToken);

    const params = route.params;

    const originAddress = params.data.originAddress;

    const destinationAddress = params.data.destinationAddress;

    const data = params.data;

    const userInfo = useSelector(selectUserInfo);

    var requests = data.requests;

    let acceptedRequestData = {
        sender_handed_over: 0,
        bringer_handed_over: 0,
        recipient_recieved_from_bringer: 0,
    };

    useLayoutEffect(() => {
        var tempData = requests.find(
            (filterItem) => data.data.accepted_request_id === filterItem.id
        );

        if (!tempData) {
            navigation.replace('waitingForBringer');
            return;
        }

        acceptedRequestData = tempData;
    }, []);

    useEffect(() => {
        if (parseInt(data.data.userId) === userInfo.id) {
            if (parseInt(acceptedRequestData.sender_handed_over) === 1) {
                setButtonDisabled(true);
                setButtonText('Bewerten');
            }
            if (
                parseInt(acceptedRequestData.bringer_handed_over) === 1 &&
                parseInt(
                    acceptedRequestData.recipient_recieved_from_bringer
                ) === 1
            ) {
                setButtonDisabled(false);
            }
        }

        if (data.data.recipient === userInfo.email) {
            if (
                !(parseInt(acceptedRequestData.bringer_handed_over) === 1) ||
                parseInt(
                    acceptedRequestData.recipient_recieved_from_bringer
                ) === 1
            ) {
                setButtonDisabled(true);
            }
            setButtonText('Empfangen');
        } else if (parseInt(acceptedRequestData.bringer_id) === userInfo.id) {
            setButtonText('Abgeben');
            if (parseInt(acceptedRequestData.bringer_handed_over) === 1) {
                setButtonDisabled(true);
            }
        }
    }, []);

    function handleNextButton() {
        if (
            parseInt(acceptedRequestData.bringer_handed_over) === 1 &&
            parseInt(acceptedRequestData.recipient_recieved_from_bringer) === 1
        ) {
            alert('Funktion noch nicht implementiert');
        } else {
            var type = '';
            var action = '';

            if (data.data.recipient === userInfo.email) {
                type = 'recipient_recieved_from_bringer';
                action = 'recieve';
            } else if (
                data.data.recipient !== userInfo.email &&
                parseInt(data.data.userId) !== userInfo.id
            ) {
                type = 'bringer_handed_over';
                action = 'handOver';
            }

            navigation.push('confirmHandOver', {
                requestID: acceptedRequestData.id,
                action,
                type,
            });
        }
    }

    return (
        <View style={tw.style('flex-1')}>
            <MapView
                initialRegion={{
                    latitude: 51.37052,
                    longitude: 10.52277,
                    latitudeDelta: 5,
                    longitudeDelta: 5,
                }}
                loadingIndicatorColor={tw.color('primary')}
                loadingBackgroundColor={tw.color('gray-300')}
                provider="google"
                mapType="standard"
                style={tw.style('w-full flex-1')}
            ></MapView>
            <View
                style={tw.style(
                    'bg-white rounded-t-3xl absolute left-5 right-5 bottom-0 shadow-lg flex py-5 px-5'
                )}
            >
                <View style={tw.style('flex flex-row')}>
                    {/*<View style={tw.style('w-1/6')}></View>*/}
                    <View style={tw.style('w-6/6 px-2')}>
                        <View style={tw.style('mb-3')}>
                            <Text style={tw.style('text-accent text-lg')}>
                                Start
                            </Text>
                            <View
                                style={tw.style(
                                    'flex flex-row justify-between'
                                )}
                            >
                                <Text style={tw.style('font-light')}>
                                    Adresse:
                                </Text>
                                <Text
                                    style={tw.style(
                                        'max-w-1/2 text-right font-light'
                                    )}
                                >
                                    {originAddress.longString}
                                </Text>
                            </View>
                            <View
                                style={tw.style(
                                    'flex flex-row justify-between'
                                )}
                            >
                                <Text style={tw.style('font-light')}>
                                    Abfahrt:
                                </Text>
                                <Text style={tw.style('max-w-1/2 font-light')}>
                                    n/a
                                </Text>
                            </View>
                            <View
                                style={tw.style(
                                    'flex flex-row justify-between'
                                )}
                            >
                                <Text style={tw.style('font-light')}>
                                    Abstand:
                                </Text>
                                <Text style={tw.style('max-w-1/2 font-light')}>
                                    n/a
                                </Text>
                            </View>
                        </View>
                        <View>
                            <View style={tw.style('flex flex-row ')}>
                                <Text
                                    style={tw.style('text-primary text-lg  ')}
                                >
                                    Ziel
                                </Text>
                            </View>
                            <View
                                style={tw.style(
                                    'flex flex-row justify-between '
                                )}
                            >
                                <Text style={tw.style('font-light')}>
                                    Adresse:
                                </Text>
                                <Text
                                    style={tw.style(
                                        'max-w-1/2 text-right font-light'
                                    )}
                                >
                                    {destinationAddress.longString}
                                </Text>
                            </View>
                            <View
                                style={tw.style(
                                    'flex flex-row justify-between'
                                )}
                            >
                                <Text style={tw.style('font-light')}>
                                    Geplante Ankunft:
                                </Text>
                                <Text style={tw.style('max-w-1/2 font-light')}>
                                    n/a
                                </Text>
                            </View>
                            <View
                                style={tw.style(
                                    'flex flex-row justify-between'
                                )}
                            >
                                <Text style={tw.style('font-light')}>
                                    Aktuelle Ankunft:
                                </Text>
                                <Text style={tw.style('max-w-1/2 font-light')}>
                                    n/a
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={tw.style('flex flex-row pt-6')}>
                    <BackButton
                        onPress={() => {
                            navigation.replace('authenticatedContainer', {
                                screen: 'uMission',
                                merge: true,
                            });
                        }}
                    />
                    <NextButton
                        onPress={() => handleNextButton()}
                        label={buttonText}
                        disabled={buttonDisabled || loading}
                        showIcon={loading}
                        loading={loading}
                    />
                </View>
            </View>
        </View>
    );
}
