import { View, Text, Image, TouchableOpacity } from 'react-native';
import React from 'react';
import tw from '../../../lib/tailwind';

import Placeholder from '../../../assets/images/icons/image_placeholder.png';
import Trash from '../../../assets/images/icons/trash-icon.png';
import Camera from '../../../assets/images/icons/camera-icon.png';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';

export default function UploadDriverLicense({ navigation }) {
    return (
        <View style={tw.style('flex-1')}>
            <Text
                style={tw.style(
                    'text-lg font-bold text-gray-700 text-center mt-7 mb-10'
                )}
            >
                Foto - Führerschein
            </Text>
            <View style={tw.style('flex-1 flex justify-between items-center')}>
                <View
                    style={tw.style(
                        'bg-gray-300 rounded-md w-8/10 h-43 flex items-center justify-center'
                    )}
                >
                    <Image
                        source={Placeholder}
                        style={tw.style('w-20 h-20')}
                        resizeMethod="scale"
                        resizeMode="contain"
                    />
                </View>
                <View style={tw.style('w-full px-12')}>
                    <View style={tw.style('flex flex-row justify-between ')}>
                        <TouchableOpacity>
                            <View
                                style={tw.style(
                                    'flex justify-center items-center'
                                )}
                            >
                                <View
                                    style={tw.style(
                                        'bg-primary rounded-lg shadow-md h-12 w-12 flex justify-center items-center'
                                    )}
                                >
                                    <Image
                                        source={Placeholder}
                                        style={tw.style('w-8 h-8')}
                                        resizeMode="contain"
                                    />
                                </View>
                                <Text style={tw.style('my-2 font-light')}>
                                    auswählen
                                </Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity>
                            <View
                                style={tw.style(
                                    'flex justify-center items-center'
                                )}
                            >
                                <View
                                    style={tw.style(
                                        'bg-primary rounded-lg shadow-md h-12 w-12 flex justify-center items-center'
                                    )}
                                >
                                    <Image
                                        source={Camera}
                                        style={tw.style('w-8 h-8')}
                                        resizeMode="contain"
                                    />
                                </View>
                                <Text style={tw.style('my-2 font-light')}>
                                    aufnehmen
                                </Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity>
                            <View
                                style={tw.style(
                                    'flex justify-center items-center'
                                )}
                            >
                                <View
                                    style={tw.style(
                                        'bg-primary rounded-lg shadow-md h-12 w-12 flex justify-center items-center'
                                    )}
                                >
                                    <Image
                                        source={Trash}
                                        style={tw.style('w-8 h-8')}
                                        resizeMode="contain"
                                    />
                                </View>
                                <Text style={tw.style('my-2 font-light')}>
                                    löschen
                                </Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                    <Text style={tw.style('mt-6 text-center')}>
                        Die Daten werden geprüft und nach DSGVO vertraulich
                        behandelt. Einwilligung zur Datenerfassung
                    </Text>
                </View>
            </View>
            <View style={tw.style('flex flex-row mx-6 mb-3')}>
                <BackButton onPress={() => navigation.goBack()} />
                <NextButton
                    label="Weiter"
                    showIcon
                    loading={false}
                    onPress={() => navigation.push('licensePlate')}
                />
            </View>
        </View>
    );
}
