import {
    View,
    Text,
    SafeAreaView,
    ScrollView,
    FlatList,
    TouchableOpacity,
    Image,
} from 'react-native';
import React, { useEffect, useState } from 'react';
import tw from '../../../lib/tailwind';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectToken, selectUserInfo } from '../../../slices/userSlice';
import PackageProgressIndicator from '../../../assets/images/icons/package/packageProgressIndicator';
import { Avatar, Icon } from 'react-native-elements';

export default function MainUMission({ navigation }) {
    const [shipments, setShipments] = useState({});

    const token = useSelector(selectToken);

    const userInfo = useSelector(selectUserInfo);

    useEffect(() => {
        axios
            .get(process.env.APIURL + 'shipments', {
                headers: { 'Authorization': `Bearer ${token}` },
            })
            .then((res) => {
                if (res.data) {
                    const temp = res.data.data;

                    setShipments([
                        { type: 'spacer', data: { id: 'uniqueSpacerId1' } },
                        ...temp,
                        { type: 'spacer', data: { id: 'uniqueSpacerId2' } },
                    ]);
                }
            })
            .catch((e) => console.log(e.response));
    }, [token]);

    return (
        <View style={tw.style('flex-1')}>
            <Text style={tw.style('text-center text-lg font-semibold py-4')}>
                Aktuelle Sendungen
            </Text>

            <FlatList
                data={shipments}
                keyExtractor={(item) => item.data.id}
                showsVerticalScrollIndicator={false}
                renderItem={({ item, index }) => {
                    if (item.type === 'spacer') return <></>;

                    var packageRelation = 'SENDER';
                    var relationColor = 'accent';

                    if (item.data.recipient === userInfo.email) {
                        packageRelation = 'EMPFÄNGER';
                        relationColor = 'gray-400';
                    }

                    if (
                        item.data.recipient !== userInfo.email &&
                        parseInt(item.data.userId) !== userInfo.id
                    ) {
                        packageRelation = 'BRINGER';
                        relationColor = 'primary';
                    }
                    var formatedPrice = item.data.price / 100;
                    formatedPrice =
                        formatedPrice.toFixed(2).replace('.', ',') + ' €';

                    var requests = item.requests;

                    let acceptedRequestData = requests.find(
                        (filterItem) =>
                            item.data.accepted_request_id === filterItem.id
                    );

                    var currentStatus = 0;

                    if (acceptedRequestData) {
                        currentStatus =
                            parseInt(
                                acceptedRequestData.bringer_recieved_from_sender
                            ) +
                            parseInt(acceptedRequestData.bringer_handed_over) +
                            parseInt(
                                acceptedRequestData.recipient_recieved_from_bringer
                            ) +
                            parseInt(acceptedRequestData.sender_handed_over);
                    }

                    console.log(currentStatus);

                    return (
                        <TouchableOpacity
                            onPress={() => {
                                if (userInfo.email === item.data.recipient) {
                                    navigation.navigate('liveTracking', {
                                        data: item,
                                    });
                                } else if (
                                    userInfo.email !== item.data.recipient &&
                                    parseInt(item.data.userId) !== userInfo.id
                                ) {
                                    if (
                                        parseInt(
                                            acceptedRequestData.bringer_recieved_from_sender
                                        ) === 0
                                    ) {
                                        navigation.navigate(
                                            'packageDetailBringer',
                                            {
                                                data: item,
                                            }
                                        );
                                    } else {
                                        navigation.navigate('liveTracking', {
                                            data: item,
                                        });
                                    }
                                } else if (!acceptedRequestData) {
                                    navigation.navigate('packageDetail', {
                                        selectedPackage: index,
                                    });
                                } else {
                                    if (
                                        acceptedRequestData &&
                                        currentStatus === 0
                                    ) {
                                        navigation.navigate('senderDetails', {
                                            data: item,
                                        });
                                    } else if (
                                        acceptedRequestData &&
                                        currentStatus > 0
                                    ) {
                                        navigation.navigate('liveTracking', {
                                            data: item,
                                        });
                                    } else if (
                                        acceptedRequestData &&
                                        currentStatus === 4
                                    ) {
                                        navigation.navigate('senderDetails', {
                                            data: item,
                                        });
                                    }
                                }
                            }}
                            key={item.data.id}
                        >
                            <View
                                style={tw.style(
                                    'bg-white rounded-lg shadow-md my-3 mx-6 pr-4 flex flex-row justify-between items-center overflow-hidden'
                                )}
                            >
                                <View
                                    style={tw.style(
                                        'flex flex-row items-center'
                                    )}
                                >
                                    <Image
                                        source={{
                                            uri:
                                                item.data.url ||
                                                'https://images.unsplash.com/photo-1577705998148-6da4f3963bc8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80',
                                        }}
                                        style={[tw.style('w-22 h-22')]}
                                    />
                                    <View style={tw.style('ml-4 p-4 ')}>
                                        <Text
                                            style={tw.style(
                                                'text-' +
                                                    relationColor +
                                                    ' font-bold'
                                            )}
                                        >
                                            {packageRelation}
                                        </Text>
                                        <Text
                                            style={tw.style(
                                                'text-lg font-semibold'
                                            )}
                                        >
                                            {item.data.name}
                                        </Text>
                                        <Text>{formatedPrice}</Text>
                                    </View>
                                </View>

                                <View
                                    style={tw.style(
                                        'flex flex-row items-center'
                                    )}
                                >
                                    {item.requests.length === 0 ||
                                    acceptedRequestData ? (
                                        <></>
                                    ) : (
                                        <>
                                            <Text>{item.requests.length}</Text>

                                            <Icon
                                                name="notifications-active"
                                                color={tw.color('accent')}
                                                containerStyle={tw.style(
                                                    'mr-4 ml-1'
                                                )}
                                            />
                                        </>
                                    )}

                                    <PackageProgressIndicator
                                        progress={currentStatus}
                                    />
                                </View>
                            </View>
                        </TouchableOpacity>
                    );
                }}
            />
        </View>
    );
}
