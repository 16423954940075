import { View, Text, Image, TextInput, ImageBackground } from 'react-native';
import React, { useEffect, useState } from 'react';
import tw from '../../../lib/tailwind';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';

import LicensePlateImage from '../../../assets/images/licenseplate.png';

export default function LicensePlate({ navigation }) {
    const [licensePlateNumber, setLicensePlateNumber] = useState('');

    const [buttonDisabled, setButtonDisabled] = useState(true);

    useEffect(() => {
        setButtonDisabled(!licensePlateNumber.match(''));
    }, [licensePlateNumber]);

    return (
        <View style={tw.style('flex-1 flex justify-between')}>
            <Text
                style={tw.style(
                    'text-lg font-bold text-gray-700 text-center mt-7 mb-10'
                )}
            >
                Kennzeichen
            </Text>

            <View>
                <ImageBackground
                    source={LicensePlateImage}
                    style={tw.style('w-full flex justify-center items-center')}
                    resizeMode="contain"
                >
                    <TextInput
                        style={tw.style(
                            'w-80 pl-15 h-20 text-3xl tracking-widest text-center'
                        )}
                        placeholder="XX - XX  XXXX"
                        onChangeText={(text) =>
                            setLicensePlateNumber(text.toUpperCase())
                        }
                        value={licensePlateNumber}
                        autoCapitalize="characters"
                    />
                </ImageBackground>
            </View>
            <View style={tw.style('flex flex-row mx-6 mb-3')}>
                <BackButton onPress={() => navigation.goBack()} />
                <NextButton
                    label="Weiter"
                    showIcon
                    loading={false}
                    disabled={buttonDisabled}
                    onPress={() => navigation.push('vehicleType')}
                />
            </View>
        </View>
    );
}
