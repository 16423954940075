import {
    View,
    Text,
    TouchableOpacity,
    Image,
    TouchableWithoutFeedback,
} from 'react-native';
import React, { useState } from 'react';
import tw from '../../../lib/tailwind';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';
import { BottomSheet, CheckBox, Icon, ListItem } from 'react-native-elements';

import Kombi from '../../../assets/images/kombi.png';
import Input from '../../../uiKit/input';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfo, setUserInformation } from '../../../slices/userSlice';

export default function VehicleType({ navigation }) {
    function sub(base, exponent) {
        return (
            <View style={{ flexDirection: 'row' }}>
                <View style={{ alignItems: 'flex-end' }}>
                    <Text style={tw.style('')}>{base}</Text>
                </View>
                <View style={{ alignItems: 'flex-start' }}>
                    <Text style={tw.style('text-xs')}>{exponent}</Text>
                </View>
            </View>
        );
    }

    const [isVisible, setIsVisible] = useState(false);

    const [hasTrailerHitch, setHasTrailerhitch] = useState(false);
    const [hasRoofRack, setHasRoofRack] = useState(false);
    const [hasBikeRack, setHasBikeRack] = useState(false);

    const [packageVolume, setPackageVolume] = useState('');

    const list = [
        {
            title: 'Motorrad - ohne/mit Koffer',
            imageSRC: '',

            onPress: null,
        },
        {
            title: 'Motorrad - ohne/mit Koffer',
            imageSRC: '',

            onPress: null,
        },
        {
            title: 'Motorrad - ohne/mit Koffer',
            imageSRC: '',

            onPress: null,
        },
    ];

    const dispatch = useDispatch();
    const userInfo = useSelector(selectUserInfo);

    function saveBringer() {
        dispatch(
            setUserInformation({ ...userInfo, extended: { licensePlate: '' } })
        );

        navigation.replace('authenticatedContainer', { screen: 'uBring' });
    }

    return (
        <View style={tw.style('flex-1 flex justify-between')}>
            <BottomSheet modalProps={{}} isVisible={isVisible}>
                {list.map((l, i) => (
                    <ListItem
                        key={i}
                        containerStyle={l.containerStyle}
                        onPress={l.onPress}
                    >
                        <ListItem.Content>
                            <ListItem.Title style={l.titleStyle}>
                                {l.title}
                            </ListItem.Title>
                        </ListItem.Content>
                    </ListItem>
                ))}
                <TouchableWithoutFeedback onPress={() => setIsVisible(false)}>
                    <View
                        style={tw.style(
                            'w-full bg-white border-t border-gray-300 py-4 px-6 flex flex-row items-center'
                        )}
                    >
                        <Icon name="close" />
                        <Text style={tw.style('ml-3')}>Schließen</Text>
                    </View>
                </TouchableWithoutFeedback>
            </BottomSheet>

            <Text
                style={tw.style(
                    'text-lg font-bold text-gray-700 text-center mt-7 mb-10'
                )}
            >
                Fahrzeugtyp
            </Text>

            <TouchableOpacity
                style={tw.style(
                    'bg-white rounded-lg shadow-md p-4 mx-12 overflow-hidden flex items-center flex flex-row justify-between'
                )}
                onPress={() => setIsVisible(true)}
            >
                <Text>Fahrzeugtyp wählen</Text>
                <View style={tw.style('flex flex-row items-center')}>
                    <Image
                        style={tw.style('h-12 w-30 mx-2')}
                        source={Kombi}
                        resizeMode="contain"
                    />
                    <Icon name="unfold-more" size={28} />
                </View>
            </TouchableOpacity>
            <View>
                <TouchableWithoutFeedback
                    style={tw.style('')}
                    onPress={() => setHasTrailerhitch(!hasTrailerHitch)}
                >
                    <View
                        style={tw.style(
                            'px-6 flex flex-row justify-between items-center'
                        )}
                    >
                        <Text>Anhängerkupplung</Text>
                        <CheckBox
                            checked={hasTrailerHitch}
                            onPress={() => setHasTrailerhitch(!hasTrailerHitch)}
                            containerStyle={tw.style(
                                'bg-background shadow-none border-0 bg-opacity-0'
                            )}
                            checkedIcon={
                                <Icon
                                    name="check-box"
                                    color={tw.color('primary')}
                                />
                            }
                            uncheckedIcon={
                                <Icon
                                    name="check-box-outline-blank"
                                    color={tw.color('primary')}
                                />
                            }
                        />
                    </View>
                </TouchableWithoutFeedback>
                <TouchableWithoutFeedback
                    style={tw.style('')}
                    onPress={() => setHasRoofRack(!hasRoofRack)}
                >
                    <View
                        style={tw.style(
                            'px-6 flex flex-row justify-between items-center'
                        )}
                    >
                        <Text>Dachgepäckträger</Text>
                        <CheckBox
                            checked={hasRoofRack}
                            onPress={() => setHasRoofRack(!hasRoofRack)}
                            containerStyle={tw.style(
                                'bg-background shadow-none border-0 bg-opacity-0'
                            )}
                            checkedIcon={
                                <Icon
                                    name="check-box"
                                    color={tw.color('primary')}
                                />
                            }
                            uncheckedIcon={
                                <Icon
                                    name="check-box-outline-blank"
                                    color={tw.color('primary')}
                                />
                            }
                        />
                    </View>
                </TouchableWithoutFeedback>
                <TouchableWithoutFeedback
                    style={tw.style('')}
                    onPress={() => setHasBikeRack(!hasBikeRack)}
                >
                    <View
                        style={tw.style(
                            'px-6 flex flex-row justify-between items-center'
                        )}
                    >
                        <Text>Fahrradträger</Text>
                        <CheckBox
                            checked={hasBikeRack}
                            onPress={() => setHasBikeRack(!hasBikeRack)}
                            containerStyle={tw.style(
                                'bg-background shadow-none border-0 bg-opacity-0'
                            )}
                            checkedIcon={
                                <Icon
                                    name="check-box"
                                    color={tw.color('primary')}
                                />
                            }
                            uncheckedIcon={
                                <Icon
                                    name="check-box-outline-blank"
                                    color={tw.color('primary')}
                                />
                            }
                        />
                    </View>
                </TouchableWithoutFeedback>
            </View>
            <View style={tw.style('bg-gray-300 h-.25 w-full')} />
            <View style={tw.style('px-6')}>
                <Text style={tw.style('font-semibold text-gray-600')}>
                    Kofferraum Volumen
                </Text>
                <View style={tw.style('flex flex-row items-center mt-4')}>
                    <Text style={tw.style('mb-3 mr-4')}>
                        {sub('Volumen m', '3')}
                    </Text>
                    <Input
                        value={packageVolume.toString()}
                        onChangeText={(text) => setPackageVolume(text)}
                        placeholder="0,0"
                        width={25}
                        textCenter
                        textColor="gray-600 font-bold"
                        keyboardType="numeric"
                    />
                </View>
            </View>

            <View style={tw.style('flex flex-row mx-6 mb-3')}>
                <BackButton onPress={() => navigation.goBack()} />
                <NextButton
                    label="Speichern"
                    showIcon
                    loading={false}
                    onPress={() => saveBringer()}
                />
            </View>
        </View>
    );
}
