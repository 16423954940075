import { View, Text, TouchableWithoutFeedback, Keyboard } from 'react-native';
import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import EditProfile from './editProfile';
import UAndMe from './u-andme';

export default function IndexUandMe() {
    const Stack = createNativeStackNavigator();

    return (
        <Stack.Navigator
            screenOptions={{ headerShown: false }}
            initialRouteName="main"
        >
            <Stack.Screen name="main" component={UAndMe} />
            <Stack.Screen name="editProfile" component={EditProfile} />
        </Stack.Navigator>
    );
}
