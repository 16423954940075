import { View, Text } from 'react-native';
import React, { useEffect } from 'react';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';
import tw from '../../../lib/tailwind';

export default function BringerPackageDetail({ navigation, route }) {
    const params = route.params;

    function handOverPackage() {
        navigation.push('confirmHandOver', {
            requestID: params.data.data.accepted_request_id,
            action: 'recieve',
            type: 'bringer_recieved_from_sender',
        });
    }
    return (
        <View style={tw.style('p-6')}>
            <View style={tw.style('flex flex-row justify-between')}>
                <BackButton onPress={() => navigation.goBack()} />
                <NextButton
                    onPress={() => handOverPackage()}
                    label="Packet annehmen"
                />
            </View>
        </View>
    );
}
