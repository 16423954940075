import { View, Text, Image } from 'react-native';
import React from 'react';
import tw from '../../../lib/tailwind';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';

import Illustration from '../../../assets/images/illustrations/u-bring-delivery-absender_3.png';

export default function RequestConfimation({ navigation }) {
    return (
        <View style={tw.style('flex-1 flex justify-evenly items-center p-6')}>
            <Text style={tw.style('font-bold text-xl')}>
                Deine Frage wurde erfolgreich verschickt!
            </Text>
            <Image
                source={Illustration}
                style={tw.style('w-5/5 h-75')}
                resizeMode="contain"
            />
            <Text style={tw.style('')}>
                Wir melden uns bei dir wenn du angenommen wurdest
            </Text>
            <View style={tw.style('flex flex-row justify-between')}>
                <BackButton onPress={() => navigation.goBack()} />
                <NextButton
                    onPress={() =>
                        navigation.replace('authenticatedContainer', {
                            screen: 'uMission',
                        })
                    }
                    label="Alles klar!"
                />
            </View>
        </View>
    );
}
