import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    Platform,
    Dimensions,
    KeyboardAvoidingView,
    ScrollView,
    TextInput,
} from 'react-native';
import { CheckBox, Icon } from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import tw from '../../../lib/tailwind';
import {
    selectRegisterUserInfo,
    setUserID,
} from '../../../slices/registerUserSlice';
import BackButton from '../../../uiKit/backButton';
import Input from '../../../uiKit/input';
import NextButton from '../../../uiKit/nextButton';
import { v4 as uuidv4 } from 'uuid';
import { selectHeaderBarLayout } from '../../../slices/headerBarSlice';

export default function LegalForm({ navigation }) {
    const [disableButton, setDisableButton] = useState(true);
    const [checkLegal, setCheckLegal] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const newUserInfo = useSelector(selectRegisterUserInfo);
    const HeaderBarLayout = useSelector(selectHeaderBarLayout);

    const dispatch = useDispatch();

    useEffect(() => {
        if (checkLegal) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    }, [checkLegal]);

    function registerUser() {
        setIsLoading(true);
        axios
            .post(process.env.APIURL + 'register', {
                'username': newUserInfo.username,
                'firstname': newUserInfo.firstname,
                'lastname': newUserInfo.lastname,
                'address': {
                    'city': newUserInfo.addressInformation.city,
                    'state': newUserInfo.addressInformation.state,
                    'latitude': newUserInfo.addressInformation.lat,
                    'longitude': newUserInfo.addressInformation.long,
                    'shortString':
                        newUserInfo.addressInformation.streetName +
                        ' ' +
                        newUserInfo.addressInformation.houseNumber,
                    'longString':
                        newUserInfo.addressInformation.streetName +
                        ' ' +
                        newUserInfo.addressInformation.houseNumber +
                        ', ' +
                        newUserInfo.addressInformation.postal +
                        ' ' +
                        newUserInfo.addressInformation.city +
                        ', Deutschland',
                    'number': newUserInfo.addressInformation.houseNumber,
                    'street': newUserInfo.addressInformation.streetName,
                    'postal': newUserInfo.addressInformation.postal,
                    'placeID': uuidv4(),
                },
                'email': newUserInfo.email,
                'telephone': newUserInfo.phoneNumber,
                'password': newUserInfo.password,
                'legal': true,
            })
            .then((res) => {
                if (res.data) {
                    console.log(res.data);
                    dispatch(setUserID(res.data.data.id));
                    setIsLoading(false);
                    navigation.reset({
                        index: 0,
                        routes: [{ name: 'validateCode' }],
                    });
                }
            })
            .catch((e) => {
                alert(
                    'Beim registrieren ist ein Fehler aufgetreten. Entweder ist der Benutzername schon vergeben, oder die E-Mail Adresse wurde bereits registriert.'
                );
            });
    }

    const titleStyle = tw.style('text-lg font-semibold mb-4');
    const paragraphStyle = tw.style('mb-3');

    return (
        <View
            style={tw.style(
                'flex-1 w-full h-full flex justify-between items-center px-6'
            )}
        >
            <View style={tw.style('w-full')}>
                <Text
                    style={tw.style(
                        'text-xl font-bold text-gray-700 text-center my-7'
                    )}
                >
                    Zustimmung AGB und Einwilligung zur Datenerfassung
                </Text>
            </View>
            <ScrollView bounces={false} style={tw.style('flex-1 w-full')}>
                <View style={tw.style('flex-1')}>
                    <Text style={titleStyle} onPress={() => {}}>
                        1) Geltungsbereich
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        1.1 Diese Allgemeinen Geschäftsbedingungen (nachfolgend
                        "AGB") der Kröber Lutz, Bastian Markus GbR – „U-bring
                        Eco-Logistics“ (nachfolgend "Vermittler"), gelten für
                        alle Verträge über den privaten Transport von Waren, die
                        Kunde A (nachfolgend „Sender“) mit Kunde B (nachfolgend
                        „Bringer“)über die U-bring APP abschließt. Hiermit wird
                        der Einbeziehungvon eigenen Bedingungen des Kunden
                        widersprochen, es sei denn, es ist etwas Anderes
                        vereinbart.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        1.2 Verbraucher im Sinne dieser AGB ist jede natürliche
                        Person, die ein Rechtsgeschäft zu Zwecken abschließt,
                        die überwiegend weder ihrer gewerblichen noch ihrer
                        selbständigenberuflichen Tätigkeit zugerechnet werden
                        können. Unternehmer im Sinne dieser AGB ist eine
                        natürliche oder juristische Person oder eine
                        rechtsfähige Personengesellschaft, die bei Abschluss
                        eines Rechtsgeschäfts in Ausübung ihrer gewerblichen
                        oder selbständigen beruflichen Tätigkeit handelt.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        1.4 Das Angebot des Vermittlers richtet sich nur an
                        Verbraucher und Unternehmer als Endverbraucher.
                    </Text>
                    <Text style={titleStyle} onPress={() => {}}>
                        2) Datenerfassung
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        2.1 Die Nutzung der Dienstleistung des
                        Vermittlerserfordert die Anlage eines Nutzerkontos für
                        alle Teilnehmer (Sender, Bringerund Empfänger) um die
                        Vernetzung aller Teilnehmersicher zu stellen.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        Für die Anlage eines Nutzerkontos ist die Erfassung
                        personenbezogener Daten erforderlich. Diese werden bei
                        der erstmaligen Anmeldung erhoben. Der Vermittlerbürgt
                        für die sichere Handhabung der Daten nach
                        Datenschutz-Grundverordnung (DSGVO). Des Weiteren
                        sichert der Vermittlerallen Teilnehmern zu,die Daten
                        keinen Dritten außerhalb der Teilnehmerder
                        Vermittlungsdienstleistungzugänglich zu machen.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        2Für die Auftragsverfolgung ist die Aktivierung der
                        Lokalisierungsfunktion auf GPS-Basis des Mobiltelefons
                        des Bringers erforderlich für den Zeitraum der
                        Erbringung der Dienstleistung. Die ausgetauschten Daten
                        werden nicht gespeichert und die Funktion per APP
                        automatisch nach Abschluss der Dienstleistung
                        deaktiviert.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        2.2 Nähere Informationen zur Datenerfassung ergeben sich
                        aus der Einwilligung zur Datenerfassung.
                    </Text>
                    <Text style={titleStyle} onPress={() => {}}>
                        3) Vertragsschluss
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        3.1 Die folgenden Regelungen über den Vertragsschluss
                        gelten für die Inanspruchnahme der online Dienstleistung
                        desVermittlers.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        3.2 Im Falle des Vertragsschlusses hinsichtlich
                        Einstellen eines Transportauftragskommt der
                        Vermittlungsvertragzwischen dem
                        SenderunddemVermittler(Schäfflerstraße 10, 85055
                        Ingolstadt, E-Mail: info@u-bring.de)zustande.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        3.3 Im Falle des Vertragsschlusses hinsichtlich
                        Durchführung eines Transportauftrags kommt der
                        Transportvertragausschließlichzwischen dem Sender und
                        dem Bringer zustande
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        3.4Die auf Online-Plattformdes Vermittlersangebotenen
                        Dienstleistungen stellen keine verbindlichen Angebote
                        seitens des Vermittlers dar, sondern dienen zur Abgabe
                        eines verbindlichen Angebots durch den Sender oder
                        Bringer.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        3.5Einstellen eines Transportauftrags-Der Senderkann
                        dasAngebot über das in der Online-Plattform des
                        Vermittlersintegrierte Online-Auftragsformular abgeben.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        Dabei gibt der Sender, nachdem er die
                        ausgewählteTransport-Dienstleistungper online-Formular
                        abgeschlossen hat, durchKlicken des den Einstellvorgang
                        abschließenden Buttons „zahlungspflichtig
                        veröffentlichen“ein rechtlich verbindliches
                        Vertragsangebot in Bezug auf die ausgewählte
                        Transport-Dienstleistungab
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        Die Dienstleistung kann jedoch nur abgegeben und
                        übermittelt werden, wenn der Senderdurch Klicken des
                        Buttons „AGB akzeptieren“ und des Buttons
                        „Widerrufsbelehrung akzeptieren“ diese
                        Vertragsbedingungen akzeptiert hat. Unmittelbar nach
                        Einstellen des Transportauftrags erhält der
                        SendervomVermittlereine Einstellbestätigung, die jedoch
                        noch keine Annahme des Vertragsangebots darstellt.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        3.5aDer Bringerkann die Annahme eines Transportauftrags
                        über das in der Online-Plattform des
                        Vermittlersintegrierte Online-Auftragsformular abgeben.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        Dabei gibt der Bringer, nachdem er den
                        ausgewähltenTransportauftrag durch Klicken des den
                        Bestellvorgang abschließenden Buttons „verbindlich
                        annehmen“ auswählt, ein rechtlich verbindliches
                        Vertragsangebot in Bezug auf den ausgewählten
                        Transportauftrag ab.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        Der Transportauftrag kann jedoch nur durchgeführtund die
                        Kontaktdaten übermittelt werden, wenn der Bringerdurch
                        Klicken des Buttons „AGB akzeptieren“ und des Buttons
                        „Widerrufsbelehrung akzeptieren“ diese
                        Vertragsbedingungen akzeptiert und dadurch in den
                        Transportvertrag einbezogen wird.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        3.6 Bei Zustandekommeneines Transportvertrags über das
                        Online-Portal des Vermittlerswird der Vertragstext nach
                        dem Vertragsschluss durch den Vermittlerautomatisiert
                        generiert, gespeichert und sowohl dem Sender, als auch
                        dem Bringer(nachfolgend „Parteien“)in Textform (E-Mail)
                        einschließlich der Widerrufsbelehrung und mit Angaben zu
                        den zu versendenden Waren und der Transportprämie
                        übermittelt. Eine darüberhinausgehende Zugänglichmachung
                        des Vertragstextes durch den Vermittler 3erfolgt nicht.
                        Die Teilnehmer habenein jeder ein Nutzerkonto im
                        Online-Portal des Vermittlerseingerichtet, über
                        welchedie Auftragsdaten durch den Vermittlerarchiviert
                        werden.Über den passwortgeschütztenZugang,unter Angabe
                        der entsprechenden Login-Daten,können die Unterlagen
                        kostenlos abgerufen werden.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        3.7 Im Rahmen des Einstellverfahrenlegt der
                        Senderzunächst dengewünschten Transportauftragan. Dort
                        kann der Senderjederzeit die gewünschten Aufträge
                        auswählen, anpassenoder entfernen. Sofern der
                        SendereinenTransportauftragaktivieren möchte, erfolgt
                        diesdurch einen Klick auf den „Zahlungspflichtig
                        veröffentlichen“-Button.Durch diese Aktion wird die
                        Erklärung des Sendersverbindlich iSd Ziffer 3.5 dieser
                        AGB.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        3.8 Im Rahmen des Vermittlungsverfahrenswählt der
                        Bringermit der U-bring APP einen Transportauftrag aus.
                        Die Auswahl erfolgt durch einen Klick auf einFähnchen
                        auf der Landkartendarstellung (Points of interest -
                        nachfolgend „POI“)der durch den Bringer angegebenen
                        Route undöffnet eine Detailansicht des
                        Transportauftrags. Durch einen Klick auf den
                        „verbindlich annehmen“ Button wird der Senderüber die
                        Kontaktaufnahme des Bringers informiert und zur
                        Bestätigung aufgefordert.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        3.9 Bestätigt der Senderdas Angbot des Bringersdurch
                        Klicken des Buttons „Bringerannehmen“, kommt ein
                        Transportvertrag zwischen Senderund Bringerzustande.
                        Dieser Transportvertrag wird automatischals
                        Dienstleistung über den Vermittlererstellt und beiden
                        Parteien inklusive der unter 3.6 aufgeführten
                        Einverständniserklärungen per E-Mail zugesandt.Bestätigt
                        der Senderden Bringernicht, kommt der Transportvertrag
                        zwischen Senderund Bringernicht zustande.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        3.10 Die Kontaktaufnahme und Transportabwicklung
                        zwischen Senderund Bringer finden ohne Beteiligung des
                        Vermittlers und in der Regel per
                        Kurznachrichtendienst(z.B. Twitter, WhatsApp,
                        etc.),E-Mail odertelefonisch statt. Die Parteien haben
                        sicherzustellen, dass die von ihnenzurBestellabwicklung
                        angegebenenE-Mail-Adressen zutreffend sind, so dass
                        unter diesenAdressendieversandten E-Mails empfangen
                        werden können. Insbesondere haben dieParteienbeidem
                        Einsatz von SPAM-Filtern sicherzustellen, dass alle zur
                        Bestellabwicklung versandten E-Mails zugestellt werden
                        können.
                    </Text>
                    <Text style={titleStyle} onPress={() => {}}>
                        4) Widerrufsrecht
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        4.1 Verbrauchern steht grundsätzlich ein Widerrufsrecht
                        zu.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        4.2 Nähere Informationen zum Widerrufsrecht ergeben sich
                        aus der Widerrufsbelehrung.
                    </Text>
                    <Text style={titleStyle} onPress={() => {}}>
                        5) Preise und Zahlungsbedingungen
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        5.1 Sofern sich aus demTransportauftragdes Senders
                        nichts Anderesergibt, handelt essich bei den angegebenen
                        Preisen um Gesamtpreise, die die gesetzliche
                        Umsatzsteuer enthalten.Die Umsatzsteuer wird in allen
                        Rechnungen separat ausgewiesen
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        5.2 Die Zahlungsmöglichkeiten aller Teilnehmer werden
                        bei Anlage des Nutzerkontos erfasst. Erst bei
                        Zustandekommen eines Transportvertrages, werden die
                        Zahlungsmöglichkeiten von Senderund Bringer über die
                        U-bring den Vertragspartnern mitgeteilt.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        5.3 Die Rechnung über die Vermittlungsgebühr erhält der
                        Sender nach Zustandekommen des Transportvertrages.Die
                        Rechnung wirdautomatisch erstellt und dem Senderper
                        E-Mail zugesandt. Darüber hinaus wird die Rechnung unter
                        dem Nutzerkontodes Sendersim Online-Portal des
                        Vermittlersauf der Website des Vermittlersarchiviert und
                        kannüber den Senderüber sein passwortgeschütztes
                        Nutzerkonto unter Angabe der entsprechenden Login-Daten
                        kostenlos abgerufen werden.Nach erfolgreichem Abschluss
                        des Transportes erhält derSenderdie
                        Zahlungsaufforderungper Kurznachrichtunter Angabe der
                        Bankverbindung, an den Bringer die Transportprämie zu
                        überweisen.
                    </Text>
                    <Text style={titleStyle} onPress={() => {}}>
                        6) Liefer-und Versandbedingungen
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        6.1 Die Lieferung von Waren erfolgt auf dem Versandweg,
                        durch den per Transportvertrag vereinbarten Bringer,an
                        die vom SenderangegebeneLieferanschrift, sofern nichts
                        Anderesvereinbart ist.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        6.2 Ist eine Zustellung beim Empfänger nicht möglich,
                        ist eine Alternative Abgabemöglichkeit (z.B. Abgabe beim
                        Nachbarn) zwischen Partnern des Transportvertrages
                        abzustimmen. Hierzu werden bei Abschluss des
                        Transportvertrages die Kontaktdaten aller drei Parteien
                        (Sender, Bringer und Empfänger) durch den Vermittler
                        mitgeteilt.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        6.3 Die Ankunft des Bringers wird dem Empfänger
                        zusätzlich per Push-Nachricht ca.10 Minuten vor Ankunft
                        angekündigt.Hierzu ist die Aktivierung der
                        Lokalisierungsfunktion auf GPS-Basis des Mobiltelefons
                        des Bringers erforderlich für den Zeitraum der
                        Erbringung der Dienstleistung. Der Bringer erklärt sich
                        hierzu mit der Einwilligung zur Datenerfassung
                        einverstanden.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        6.4 Erfolgt die Lieferung des Transportgutes nicht
                        innerhalb des zwischen Sender und Bringer vereinbarten
                        Zeitfensters und ist der Bringer auch über alle weiteren
                        Kontaktmittel nicht erreichbar, werden dem Sender
                        weitere Personendaten zum Bringer durch U-bring
                        übermittelt. (z.B. fotografische Kopie des
                        Führerscheins)
                    </Text>
                    <Text style={titleStyle} onPress={() => {}}>
                        7) Haftungsbeschränkung
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        7.1 Ansprüche des Senders und des Bringers, sowie des
                        Empfängersauf Schadensersatz beim Vermittlersind
                        ausgeschlossen.
                    </Text>
                    <Text style={titleStyle} onPress={() => {}}>
                        8) Anwendbares Recht
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        Für sämtliche Rechtsbeziehungen der Parteien gilt das
                        Recht der Bundesrepublik Deutschland unter Ausschluss
                        der Vorschriften des internationalen Privatrechts sowie
                        des UN-Kaufrechts. Bei Verbrauchern gilt diese
                        Rechtswahl nur insoweit, als nicht der gewährte Schutz
                        durch zwingende Bestimmungen des Rechts des Staates, in
                        dem der Verbraucher seinen gewöhnlichen Aufenthalt hat,
                        entzogen wird.
                    </Text>
                    <Text style={titleStyle} onPress={() => {}}>
                        9) Gerichtsstand
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        9.1 Für den Sender als Auftraggeber und den Bringer als
                        Auftragnehmer mit Sitz im Hoheitsgebiet der
                        Bundesrepublik Deutschland, ist ausschließlicher
                        Gerichtsstand für alle Streitigkeiten aus diesem Vertrag
                        der Wohnsitz des Senders.
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        9.2 Die Kröber Lutz, Bastian Markus GbR –„U-bring
                        Eco-Logistics“ ist ausschließlich Vermittler des
                        Transportauftrags undist zur Teilnahme an einem
                        Streitbeilegungsverfahren vor einer
                        Verbraucherschlichtungsstelle weder verpflichtet noch
                        bereit.
                    </Text>
                    <Text style={titleStyle} onPress={() => {}}>
                        10) Alternative Streitbeilegung
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        10.1 Die EU-Kommission stellt im Internet unter
                        folgendem Link eine Plattform zur Online-Streitbeilegung
                        bereit:
                    </Text>
                    <Text style={paragraphStyle} onPress={() => {}}>
                        Diese Plattform dient als Anlaufstelle zur
                        außergerichtlichen Beilegung von Streitigkeiten
                        ausOnline-Dienstleistungsverträgen, an denen ein
                        Verbraucher beteiligt ist.
                    </Text>
                    <CheckBox
                        checked={checkLegal}
                        onPress={() => setCheckLegal(!checkLegal)}
                        containerStyle={tw.style(
                            'bg-background shadow-none border-0 bg-opacity-0'
                        )}
                        checkedIcon={
                            <Icon
                                name="radio-button-checked"
                                color={tw.color('primary')}
                            />
                        }
                        uncheckedIcon={
                            <Icon
                                name="radio-button-unchecked"
                                color={tw.color('primary')}
                            />
                        }
                        title={
                            'Ich habe die AGB gelesen und aktzeptiere die Verarbeitung meiner Daten.'
                        }
                    />
                </View>
            </ScrollView>
            <KeyboardAvoidingView
                behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                style={tw.style('mb-4 pt-2')}
                keyboardVerticalOffset={HeaderBarLayout.height + 14}
            >
                <View style={tw.style('flex flex-row justify-center w-full')}>
                    <BackButton onPress={() => navigation.goBack()} />
                    <NextButton
                        onPress={() => registerUser()}
                        label="Registrieren"
                        showIcon
                        disabled={disableButton}
                        loading={isLoading}
                    />
                </View>
            </KeyboardAvoidingView>
        </View>
    );
}
