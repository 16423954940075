import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    KeyboardAvoidingView,
    Platform,
    Dimensions,
} from 'react-native';
import { CheckBox, Icon } from 'react-native-elements';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import tw from '../../../lib/tailwind';
import { selectHeaderBarLayout } from '../../../slices/headerBarSlice';
import { setAddressInformation } from '../../../slices/registerUserSlice';
import BackButton from '../../../uiKit/backButton';
import Input from '../../../uiKit/input';
import NextButton from '../../../uiKit/nextButton';
import uuid from 'uuid';

export default function AdressForm({ navigation }) {
    const dispatch = useDispatch();

    const HeaderBarLayout = useSelector(selectHeaderBarLayout);

    const [location, setLocation] = useState({
        streetName: null,
        houseNumber: null,
        postal: null,
        city: null,
        lat: null,
        long: null,
        state: null,
    });

    const [buttonDisabled, setButtonDisabled] = useState(true);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const { city, houseNumber, lat, long, postal, streetName, state } =
            location;

        if (
            city &&
            houseNumber &&
            lat &&
            long &&
            postal &&
            streetName &&
            state
        ) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [location]);

    function saveEntry() {
        setIsLoading(true);
        dispatch(
            setAddressInformation({
                streetName: location.streetName,
                houseNumber: location.houseNumber,
                city: location.city,
                long: location.long,
                lat: location.lat,
                postal: location.postal,
                state: location.state,
            })
        );
        setIsLoading(false);
        navigation.push('phoneNumberForm');
    }

    return (
        <View
            style={tw.style(
                'w-full h-full flex justify-between items-center px-6'
            )}
        >
            <View style={tw.style('w-full flex-1')}>
                <Text
                    style={tw.style(
                        'text-xl font-bold text-gray-700 text-center my-7'
                    )}
                >
                    Bitte gib deine Adresse ein.
                </Text>

                <GooglePlacesAutocomplete
                    styles={{
                        'textInput': tw.style(''),
                        'textInputContainer': tw.style(
                            'bg-white rounded-xl shadow-md py-4 px-5'
                        ),
                        'listView': tw.style('mt-1 rounded-xl mb-2'),
                        'row': tw.style('bg-white p-3 px-2 '),
                        'separator': tw.style('bg-gray-300 h-.1'),
                    }}
                    placeholder="Adresse"
                    nearbyPlacesAPI="GooglePlacesSearch"
                    debounce={400}
                    enablePoweredByContainer={false}
                    query={{
                        key: process.env.GOOGLE_API_KEY,
                        language: 'de',
                        components: 'country:de',
                    }}
                    minLength={2}
                    fetchDetails={true}
                    returnKeyType="Suchen"
                    suppressDefaultStyles
                    onPress={(data, details = null) => {
                        var houseNumber = 0;
                        var streetName = '';
                        var postal = 0;
                        var city = '';
                        var state = '';

                        details.address_components.map(
                            ({ types, long_name }) => {
                                switch (types[0]) {
                                    case 'street_number':
                                        houseNumber = long_name;
                                        break;

                                    case 'route':
                                        streetName = long_name;
                                        break;

                                    case 'administrative_area_level_1':
                                        state = long_name;
                                        break;

                                    case 'postal_code':
                                        postal = long_name;
                                        break;

                                    case 'locality':
                                        city = long_name;
                                        break;

                                    default:
                                        break;
                                }
                            }
                        );

                        setLocation({
                            streetName: streetName,
                            houseNumber: houseNumber,
                            postal: postal,
                            city: city,
                            lat: details.geometry.location.lat,
                            long: details.geometry.location.lng,
                            state: state,
                            placeID: uuid.v4(),
                        });
                    }}
                />
            </View>
            <KeyboardAvoidingView
                behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                style={tw.style('mb-4')}
                keyboardVerticalOffset={HeaderBarLayout.height + 14}
            >
                <View style={tw.style('flex flex-row justify-center w-full')}>
                    <BackButton onPress={() => navigation.goBack()} />
                    <NextButton
                        onPress={() => saveEntry()}
                        label="Weiter"
                        showIcon
                        disabled={buttonDisabled}
                        loading={isLoading}
                    />
                </View>
            </KeyboardAvoidingView>
        </View>
    );
}
