import {
    View,
    Text,
    Animated,
    Image,
    Dimensions,
    ScrollView,
    FlatList,
    TouchableOpacity,
} from 'react-native';
import React from 'react';
import tw from '../lib/tailwind';
import { Avatar, Icon, Rating } from 'react-native-elements';

export default function PackageCard(props) {
    if (props.data.type && props.data.type === 'spacer')
        return <View style={{ width: props.containerWidth / 4 }} />;

    const windowWidth = Dimensions.get('screen').width;

    const bringerArr = props.data.requests;

    return (
        <View style={[tw.style('flex-1'), { width: props.containerWidth }]}>
            <Animated.View
                style={[
                    tw.style(''),
                    { transform: [{ translateY: props.translateY }] },
                ]}
            >
                <Animated.Text
                    numberOfLines={1}
                    style={[
                        tw.style('text-xl font-semibold mx-3'),
                        { opacity: props.opacity },
                    ]}
                >
                    {props.data.data.name}
                </Animated.Text>
                <View
                    style={tw.style(
                        'bg-white rounded-lg shadow-lg m-3 overflow-hidden'
                    )}
                >
                    <Image
                        source={{
                            uri: 'https://images.unsplash.com/photo-1577705998148-6da4f3963bc8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80',
                        }}
                        style={tw.style('h-64 w-full')}
                    />
                </View>
            </Animated.View>
            <ScrollView
                style={[
                    tw.style('mt-13 pt-3'),
                    { width: windowWidth, marginLeft: -(windowWidth / 6) },
                ]}
                showsVerticalScrollIndicator={false}
                nestedScrollEnabled={true}
            >
                <Animated.View
                    style={[
                        tw.style('px-6'),
                        {
                            opacity: props.opacity,
                        },
                    ]}
                >
                    {bringerArr.length !== 0 ? (
                        bringerArr.map((item, index) => (
                            <TouchableOpacity
                                onPress={() =>
                                    props.navigation.push('acceptBringer', {
                                        packageData: props.data,
                                        bringerData: item,
                                    })
                                }
                                key={index}
                            >
                                <View
                                    style={tw.style(
                                        'bg-white rounded-2xl shadow-lg p-3 mb-4 flex flex-row justify-between'
                                    )}
                                >
                                    <View
                                        style={tw.style(
                                            'flex flex-row items-center'
                                        )}
                                    >
                                        <Avatar
                                            rounded
                                            size={56}
                                            source={{
                                                uri:
                                                    'https://api.multiavatar.com/' +
                                                    item.user_email +
                                                    '.png',
                                            }}
                                            icon={{
                                                name: 'person',
                                                color: 'white',
                                                size: 36,
                                            }}
                                            containerStyle={tw.style(
                                                'bg-gray-400'
                                            )}
                                        />

                                        <View
                                            style={tw.style(
                                                'ml-4 flex items-start'
                                            )}
                                        >
                                            <Text
                                                style={tw.style(
                                                    'text-lg font-semibold'
                                                )}
                                            >
                                                {item.display_name}
                                            </Text>
                                            <Rating
                                                readonly
                                                startingValue={item.rating}
                                                imageSize={14}
                                            />
                                        </View>
                                    </View>
                                </View>
                            </TouchableOpacity>
                        ))
                    ) : (
                        <View
                            style={tw.style(
                                'bg-white rounded-2xl shadow-lg p-3 mb-4 flex flex-row justify-between'
                            )}
                        >
                            <View
                                style={tw.style('flex flex-row items-center')}
                            >
                                <Avatar
                                    rounded
                                    size={56}
                                    icon={{
                                        name: 'speaker-notes-off',
                                        color: 'white',
                                        size: 36,
                                    }}
                                    containerStyle={tw.style('bg-gray-400')}
                                />
                                <View style={tw.style('ml-4 flex items-start')}>
                                    <Text
                                        style={tw.style(
                                            'text-lg font-semibold'
                                        )}
                                    >
                                        Leider gibt es noch keine Anfragen
                                    </Text>
                                </View>
                            </View>
                        </View>
                    )}
                </Animated.View>
            </ScrollView>
        </View>
    );
}
