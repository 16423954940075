import { View, Text, TouchableOpacity } from 'react-native';
import React, { useState } from 'react';
import tw from '../../../lib/tailwind';
import { Avatar, CheckBox, Icon, Rating } from 'react-native-elements';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';
import { useSelector } from 'react-redux';
import { selectToken, selectUserInfo } from '../../../slices/userSlice';
import axios from 'axios';

export default function AcceptBringer({ navigation, route }) {
    const params = route.params;

    const bringerData = params.bringerData;
    const packageData = params.packageData;

    const [checkLegal, setCheckLegal] = useState(false);
    const [loading, setLoading] = useState(false);

    const token = useSelector(selectToken);

    const userInfo = useSelector(selectUserInfo);

    function acceptRequest() {
        setLoading(true);
        axios
            .post(
                process.env.APIURL + 'shipment/request',
                {
                    requestID: parseInt(bringerData.id),
                    type: 'acceptRequest',
                },
                {
                    headers: { 'Authorization': `Bearer ${token}` },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    navigation.replace('authenticatedContainer', {
                        screen: 'uMission',
                        merge: true,
                    });
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    }

    return (
        <View style={tw.style('flex-1 flex justify-between p-6')}>
            <View>
                <View style={tw.style('flex flex-row items-center pt-5 pb-10')}>
                    <Avatar
                        rounded
                        size={72}
                        source={{
                            uri:
                                'https://api.multiavatar.com/' +
                                bringerData.user_email +
                                '.png',
                        }}
                        containerStyle={tw.style('shadow-md mr-4')}
                    />
                    <View style={tw.style('flex')}>
                        <Text style={tw.style('text-accent font-semibold')}>
                            BRINGER
                        </Text>
                        <Text>{bringerData.display_name}</Text>
                        <Rating
                            fractions={1}
                            startingValue={3.6}
                            readonly
                            imageSize={15}
                            tintColor={tw.color('background')}
                            style={{
                                paddingVertical: 10,
                            }}
                        />
                    </View>
                </View>
                <View>
                    <View style={tw.style('flex flex-row')}>
                        <Text style={tw.style('font-medium mr-3 mb-2')}>
                            Adresse VON
                        </Text>
                        <Text>{packageData.originAddress.longString}</Text>
                    </View>
                </View>
                <View>
                    <View style={tw.style('flex flex-row')}>
                        <Text style={tw.style('font-medium mr-3')}>
                            Adresse NACH
                        </Text>
                        <Text>{packageData.destinationAddress.longString}</Text>
                    </View>
                </View>
            </View>
            <View>
                <View style={tw.style('py-8')}>
                    <CheckBox
                        checked={checkLegal}
                        onPress={() => setCheckLegal(!checkLegal)}
                        containerStyle={tw.style(
                            'bg-background shadow-none border-0 bg-opacity-0'
                        )}
                        checkedIcon={
                            <Icon
                                name="radio-button-checked"
                                color={tw.color('primary')}
                            />
                        }
                        uncheckedIcon={
                            <Icon
                                name="radio-button-unchecked"
                                color={tw.color('primary')}
                            />
                        }
                        title={
                            'Ich stimme der AGB ,der Widerrufsbelehrung und dem Transportvertrag zu.'
                        }
                    />
                </View>
                <View style={tw.style('flex flex-row')}>
                    <BackButton onPress={() => navigation.goBack()} />
                    <NextButton
                        showIcon
                        onPress={() => acceptRequest()}
                        disabled={!checkLegal || loading}
                        loading={loading}
                        label="Kostenpflichtig versenden"
                    />
                </View>
            </View>
        </View>
    );
}
