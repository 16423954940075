import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import {
    View,
    Text,
    KeyboardAvoidingView,
    Platform,
    Dimensions,
    TextInput,
    Keyboard,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import tw from '../../../lib/tailwind';
import { selectHeaderBarLayout } from '../../../slices/headerBarSlice';
import { selectRegisterUserInfo } from '../../../slices/registerUserSlice';
import { setToken, setUserInformation } from '../../../slices/userSlice';
import BackButton from '../../../uiKit/backButton';
import Input from '../../../uiKit/input';
import NextButton from '../../../uiKit/nextButton';

export default function ValidateCode({ navigation }) {
    const [verifyCodeInput, setVerifyCode] = useState('');

    const [buttonDisabled, setButtonDisabled] = useState(true);

    const [isLoading, setIsLoading] = useState(false);

    const newUserInfo = useSelector(selectRegisterUserInfo);

    const dispatch = useDispatch();

    const HeaderBarLayout = useSelector(selectHeaderBarLayout);

    useEffect(() => {
        if (verifyCodeInput.length === 6) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [verifyCodeInput]);

    function verifyCode() {
        setIsLoading(true);
        axios
            .post(process.env.APIURL + 'verify', {
                'code': verifyCodeInput,
                'id': newUserInfo.userID,
            })
            .then((res) => {
                if (!res.data.error && res.status === 200) {
                    const data = res.data.data;
                    dispatch(setToken(data.accessToken));
                    dispatch(setUserInformation(data.user));
                    setIsLoading(false);
                    navigation.replace('authenticatedContainer', {
                        screen: newUserInfo.arrivalScreenAfterRegistration,
                    });
                } else {
                    setIsLoading(false);
                    alert('Es ist ein Fehler aufgetreten');
                }
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response) {
                    console.log(error.response.data);
                    alert(error.response.data.message);
                }
            });
    }

    return (
        <View
            style={tw.style(
                'w-full h-full flex justify-between items-center px-6'
            )}
        >
            <View style={tw.style('w-full')}>
                <Text
                    style={tw.style(
                        'text-xl font-bold text-gray-700 text-center my-7'
                    )}
                >
                    Bitte gib den Dir per E-Mail zugesandten Bestätigungscode
                    ein.
                </Text>

                <View
                    style={tw.style(
                        'flex flex-col items-center justify-center'
                    )}
                >
                    <Input
                        onChangeText={(text) => setVerifyCode(text)}
                        value={verifyCodeInput}
                        placeholder="Code"
                    />
                    <Text
                        style={tw.style(
                            'text-xs text-gray-400 font-light mt--2 mb-4'
                        )}
                    >
                        (Überprüfe auch dein Spam-Ordner)
                    </Text>
                </View>
            </View>

            <KeyboardAvoidingView
                behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                style={tw.style('mb-4')}
                keyboardVerticalOffset={HeaderBarLayout.height + 14}
            >
                <View style={tw.style('flex flex-row justify-center w-full')}>
                    <BackButton onPress={() => {}} hidden />
                    <NextButton
                        onPress={() => verifyCode()}
                        label="Verifizieren"
                        disabled={buttonDisabled}
                        loading={isLoading}
                        showIcon={isLoading}
                    />
                </View>
            </KeyboardAvoidingView>
        </View>
    );
}
