import React from 'react';
import { View, Text, TouchableHighlight, TouchableOpacity } from 'react-native';
import { Avatar, Icon, Rating } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import tw from '../../../lib/tailwind';
import {
    selectUserInfo,
    setToken,
    setUserInformation,
} from '../../../slices/userSlice';
import appInfo from '../../../app.json';

export default function UAndMe({ navigation }) {
    const userInfo = useSelector(selectUserInfo);
    console.log(userInfo);

    const dispatch = useDispatch();

    const ListEntrys = [
        {
            icon: 'alt-route',
            label: 'Meine Routen',
            route: 'uBring',
            screen: 'route',
        },
        { icon: 'local-shipping', label: 'Meine Sendungen', route: 'uMission' },
        { icon: 'settings', label: 'Konto bearbeiten', route: 'editProfile' },
    ];

    function handleLogout() {
        dispatch(setUserInformation(null));
        dispatch(setToken());
        navigation.navigate('start', { merge: true });
    }

    return (
        <SafeAreaView edges={['left', 'right']} style={tw.style('flex-1 px-6')}>
            <View style={tw.style('flex-1')}>
                <View
                    style={tw.style(
                        'w-full flex flex-row items-center my-4 mb-8'
                    )}
                >
                    <Avatar
                        source={{
                            uri:
                                userInfo.avatarSrc !== ''
                                    ? userInfo.avatarSrc
                                    : 'https://api.multiavatar.com/' +
                                      userInfo.email +
                                      '.png',
                        }}
                        size={64}
                        rounded
                        containerStyle={tw.style('shadow-md bg-white')}
                    >
                        <Avatar.Accessory
                            size={22}
                            iconProps={{ name: 'edit' }}
                            onPress={() => {
                                navigation.push('editProfile');
                            }}
                        />
                    </Avatar>
                    <View style={tw.style('flex-1 ml-6')}>
                        <View
                            style={tw.style(
                                'flex-1 flex-row justify-between items-center'
                            )}
                        >
                            <Text style={tw.style('text-xl font-bold')}>
                                {userInfo.firstname + ' ' + userInfo.lastname}
                            </Text>
                            <Text style={tw.style('text-accent font-semibold')}>
                                U-bring
                            </Text>
                        </View>
                        <View
                            style={tw.style(
                                'flex-1 flex-row justify-between items-center'
                            )}
                        >
                            <Rating
                                fractions={1}
                                startingValue={3.6}
                                readonly
                                imageSize={15}
                                tintColor={tw.color('background')}
                                style={{
                                    paddingVertical: 10,
                                }}
                            />
                            <Text style={tw.style('text-accent font-semibold')}>
                                U-send
                            </Text>
                        </View>
                    </View>
                </View>
                {ListEntrys.map((entry, id) => (
                    <TouchableOpacity
                        key={id}
                        onPress={() =>
                            navigation.navigate(
                                entry.route,
                                entry.screen ? { screen: entry.screen } : {}
                            )
                        }
                    >
                        <View
                            style={tw.style(
                                'py-4 w-full border-b border-gray-200 flex flex-row items-center justify-between'
                            )}
                        >
                            <View
                                style={tw.style(
                                    'flex flex-row items-center                                                   '
                                )}
                            >
                                <Icon
                                    name={entry.icon}
                                    containerStyle={tw.style('mr-3')}
                                    color={tw.color('gray-700')}
                                />
                                <Text>{entry.label}</Text>
                            </View>
                            <Icon
                                name="chevron-right"
                                containerStyle={tw.style('mr-3')}
                                color={tw.color('gray-700')}
                            />
                        </View>
                    </TouchableOpacity>
                ))}
                <TouchableOpacity onPress={() => handleLogout()}>
                    <View
                        style={tw.style(
                            'py-4 w-full flex flex-row items-center justify-between'
                        )}
                    >
                        <View
                            style={tw.style(
                                'flex flex-row items-center                                                   '
                            )}
                        >
                            <Icon
                                name="power-settings-new"
                                containerStyle={tw.style('mr-3')}
                                color={tw.color('red-400')}
                            />
                            <Text style={tw.style('text-red-400')}>
                                Abmelden
                            </Text>
                        </View>
                    </View>
                </TouchableOpacity>
                <Text style={tw.style('text-center font-thin my-6')}>
                    App-Version: {appInfo.expo.version}
                </Text>
            </View>
        </SafeAreaView>
    );
}
