import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { View, Text, ScrollView } from 'react-native';
import { Icon, FAB } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';
import tw from '../../../lib/tailwind';
import { selectToken } from '../../../slices/userSlice';
import Accordion from '../../../uiKit/accordion';

export default function UBring({ navigation }) {
    const token = useSelector(selectToken);

    const [routes, setRoutes] = useState([]);

    useEffect(() => {
        console.log(token);
        axios
            .get(process.env.APIURL + 'routes', {
                headers: { 'Authorization': `Bearer ${token}` },
            })
            .then((res) => {
                if (res?.data?.data) {
                    setRoutes(res.data.data);
                    console.log(res.data.data);
                }
            })
            .catch((e) => console.log(e));
    }, [token]);

    function addRoute() {}

    return (
        <View style={tw.style('flex-1')}>
            <ScrollView
                contentContainerStyle={tw.style('min-h-full')}
                style={tw.style('relative')}
            >
                <View style={tw.style('flex-1')}>
                    {routes.length > 0 ? (
                        routes.map((data, id) => (
                            <Accordion
                                title={
                                    data.originAddress.city +
                                    '   ->   ' +
                                    data.destinationAddress.city
                                }
                                key={id}
                                onClickPackages={() =>
                                    navigation.push('showPackages', {
                                        data: data,
                                    })
                                }
                                {...data}
                            />
                        ))
                    ) : (
                        <View
                            style={tw.style(
                                'flex-1 flex justify-center items-center'
                            )}
                        >
                            <Text>Noch hast du keine Routen gespeichert</Text>
                            <View
                                style={tw.style('absolute bottom-20 right-8')}
                            >
                                <Text
                                    style={tw.style(
                                        'mb-2 text-gray-400 font-bold'
                                    )}
                                >
                                    Jetzt eine Route anlegen
                                </Text>
                                <Icon
                                    name="undo"
                                    containerStyle={{
                                        transform: [{ rotate: '-90deg' }],
                                    }}
                                    size={48}
                                    color={tw.color('gray-400')}
                                />
                            </View>
                        </View>
                    )}
                </View>
            </ScrollView>
            <FAB
                icon={<Icon name="add" color={tw.color('white')} />}
                color={tw.color('primary')}
                containerStyle={tw.style('absolute right-2 bottom-2')}
                placement="right"
                size="large"
                onPress={() => navigation.push('addRoute')}
            />
        </View>
    );
}
