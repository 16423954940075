import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
    Dimensions,
    Keyboard,
    KeyboardAvoidingView,
    Platform,
    TouchableOpacity,
    TouchableWithoutFeedback,
} from 'react-native';
import { TextInput } from 'react-native';
import { View, Text } from 'react-native';
import { Icon } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import tw from '../../lib/tailwind';
import { selectHeaderBarLayout } from '../../slices/headerBarSlice';
import { setArrivalScreenAfterRegistration } from '../../slices/registerUserSlice';
import { setToken, setUserInformation } from '../../slices/userSlice';
import BackButton from '../../uiKit/backButton';
import HeaderBar from '../../uiKit/headerBar';
import Input from '../../uiKit/input';
import NextButton from '../../uiKit/nextButton';

export default function LoginScreen({ navigation, route }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [buttonLoading, setButtonLoading] = useState(false);

    const HeaderBarLayout = useSelector(selectHeaderBarLayout);

    const nextScreen = route.params.screen;

    useEffect(() => {
        //console.log(HeaderBarLayout);
    }, [HeaderBarLayout]);

    const dispatch = useDispatch();

    function signIn() {
        Keyboard.dismiss();
        console.log(process.env.APIURL);
        if (email.trim() === '') {
            alert('Error Email');

            return;
        }

        if (password.trim() === '') {
            alert('Error Password');
            return;
        }
        setButtonLoading(true);
        axios
            .post(
                process.env.APIURL + 'token',
                { username: email.trim(), password: password.trim() },
                {
                    'headers': {
                        'content-type': 'application/json',
                    },
                }
            )
            .then((res) => {
                if (res.data.code != 2) {
                    const data = res.data.data;
                    console.log(data.accessToken);
                    dispatch(setToken(data.accessToken));
                    dispatch(setUserInformation(data.user));
                    setButtonLoading(false);
                    navigation.replace('authenticatedContainer', {
                        screen: nextScreen,
                    });
                }
            })
            .catch((error) => {
                setButtonLoading(false);
                if (error.response) {
                    console.log(error.response.data);
                    alert(error.response.data.message);
                }
            });
    }

    return (
        <TouchableWithoutFeedback
            onPress={() => {
                if (Platform.OS != 'web') {
                    Keyboard.dismiss();
                }
            }}
        >
            <View style={tw.style('flex-1 bg-background')}>
                <HeaderBar siteTitle="U-login" />
                <View style={tw.style('flex-1 justify-between px-6')}>
                    <View style={tw.style('')}>
                        <Text
                            style={tw.style(
                                'font-bold text-xl my-7 text-center'
                            )}
                        >
                            Melde dich an und los geht's!
                        </Text>
                        <Input
                            onChangeText={(text) => setEmail(text)}
                            value={email}
                            accessibilityLabel="E-Mail eingeben"
                            accessibilityRole="text"
                            placeholder="E-Mail"
                            autoCapitalize="none"
                            keyboardType="email-address"
                        />
                        <Input
                            onChangeText={(text) => setPassword(text)}
                            value={password}
                            secureTextEntry={true}
                            accessibilityLabel="Passwort eingeben"
                            accessibilityRole="text"
                            clearButtonMode="while-editing"
                            placeholder="Passwort"
                        />
                        <TouchableOpacity style={tw.style('mt-3')}>
                            <Text style={tw.style('text-primary text-lg')}>
                                Passwort vergessen?
                            </Text>
                        </TouchableOpacity>
                    </View>

                    <KeyboardAvoidingView
                        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                        keyboardVerticalOffset={HeaderBarLayout.height + 14}
                    >
                        <TouchableOpacity
                            style={tw.style('mb-3')}
                            onPress={() => {
                                navigation.push('register');
                                dispatch(
                                    setArrivalScreenAfterRegistration(
                                        nextScreen
                                    )
                                );
                            }}
                        >
                            <Text style={tw.style('text-primary text-lg')}>
                                Noch kein Konto? Registrier dich!
                            </Text>
                        </TouchableOpacity>
                        <View
                            style={tw.style(
                                'flex flex-row justify-center w-full mb-4'
                            )}
                        >
                            <BackButton onPress={() => navigation.goBack()} />
                            <NextButton
                                onPress={() => signIn()}
                                label="Anmelden"
                                showIcon={buttonLoading}
                                loading={buttonLoading}
                                disabled={buttonLoading}
                            />
                        </View>
                    </KeyboardAvoidingView>
                </View>
            </View>
        </TouchableWithoutFeedback>
    );
}
