import React from 'react';
import { Text, TextInput, View } from 'react-native';
import tw from '../lib/tailwind';

export default function Input({
    placeholder = '',
    onChangeText,
    value,
    width = 'full',
    accessibilityLabel,
    accessibilityRole,
    autoCapitalize = 'none',
    secureTextEntry,
    keyboardType = 'default',
    type,
    disabled = false,
    textCenter,
    textColor = 'black',
    onContentSizeChange,
    onPress,
}) {
    return (
        <View
            style={tw.style(
                'flex flex-row items-center bg-white rounded-xl shadow-md mb-4 w-' +
                    width
            )}
        >
            {type === 'phone' ? (
                <Text style={tw.style('ml-4 mr--2 font-semibold text-3xl')}>
                    🇩🇪
                </Text>
            ) : (
                <></>
            )}
            <TextInput
                style={tw.style(
                    'flex-1 h-full py-4 px-5 ' +
                        (textCenter ? 'text-center ' : '') +
                        'text-' +
                        textColor
                )}
                onChangeText={onChangeText}
                value={value}
                accessibilityLabel={accessibilityLabel}
                accessibilityRole={accessibilityRole}
                clearButtonMode="while-editing"
                placeholder={placeholder}
                autoCapitalize={autoCapitalize}
                secureTextEntry={secureTextEntry}
                keyboardType={keyboardType}
                maxLength={256}
                editable={!disabled}
                selectTextOnFocus={!disabled}
                onContentSizeChange={onContentSizeChange}
                onPressIn={onPress}
                pointerEvents={disabled ? 'none' : 'box-only'}
            />
        </View>
    );
}
