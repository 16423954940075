import { View, Text } from 'react-native';
import React, { useEffect, useState } from 'react';
import tw from '../../../lib/tailwind';
import { Avatar } from 'react-native-elements';
import NextButton from '../../../uiKit/nextButton';
import BackButton from '../../../uiKit/backButton';
import { useSelector } from 'react-redux';
import { selectToken } from '../../../slices/userSlice';
import axios from 'axios';

export default function PackageInfo({ navigation, route }) {
    const params = route.params?.data;

    var formatedPrice = params.price / 100;

    formatedPrice = formatedPrice.toFixed(2).replace('.', ',') + ' €';

    const token = useSelector(selectToken);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!params) {
            navigation.goBack();
        }
        console.log(params);
    }, []);

    function proposeToPackage() {
        setLoading(true);
        axios
            .put(
                process.env.APIURL + 'shipment/request',
                {
                    shipmentID: parseInt(params.id),
                },
                {
                    headers: { 'Authorization': `Bearer ${token}` },
                }
            )
            .then((res) => {
                if (res.data) {
                    navigation.push('requestConfirmation');
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e.response);
                setLoading(false);
            });
    }

    return (
        <View style={tw.style('flex-1 p-6')}>
            <View>
                <Avatar size={84} />
            </View>
            <View style={tw.style('bg-white p-6 rounded-xl shadow-md')}>
                <Text>{formatedPrice}</Text>
            </View>
            <View style={tw.style('flex flex-row justify-between')}>
                <BackButton onPress={() => navigation.goBack()} />
                <NextButton
                    label="Bewerben"
                    onPress={() => proposeToPackage()}
                    loading={loading}
                    disabled={loading}
                    showIcon={loading}
                />
            </View>
        </View>
    );
}
