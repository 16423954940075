import { View, Text, TouchableWithoutFeedback, Keyboard } from 'react-native';
import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import USendMain from './main';
import CreatePackage from './createPackage';
import SetDestination from './setDestination';
import SetOrigin from './setOrigin';
import { SafeAreaView } from 'react-native-safe-area-context';
import tw from '../../../lib/tailwind';

export default function USendIndex() {
    const Stack = createNativeStackNavigator();
    return (
        <SafeAreaView
            edges={['left', 'right', 'bottom']}
            style={tw.style('flex-1')}
        >
            <Stack.Navigator
                screenOptions={{ headerShown: false }}
                initialRouteName="createPackage"
            >
                <Stack.Screen name="createPackage" component={CreatePackage} />
                <Stack.Screen name="setOrigin" component={SetOrigin} />
                <Stack.Screen
                    name="setDestination"
                    component={SetDestination}
                />
            </Stack.Navigator>
        </SafeAreaView>
    );
}
