import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StatusBar } from 'expo-status-bar';
import AppLoading from 'expo-app-loading';
import { useEffect, useState } from 'react';
import { KeyboardAvoidingView, StyleSheet, Text, View } from 'react-native';
import tw from './lib/tailwind';
import FirstOpenIndex from './screens/firstOpen';
import { useDeviceContext } from 'twrnc';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import LoginContainer from './screens/login/loginContainer';
import StartScreen from './screens/start';
import Index from './screens/authenticatedScreens';
import { Provider } from 'react-redux';
import { store } from './store';

export default function App() {
    useDeviceContext(tw);

    const Stack = createNativeStackNavigator();

    const [isAppReady, setIsAppReady] = useState(false);

    const [isFirstOpen, setIsFirstOpen] = useState(true);

    function testforFirstOpen() {
        AsyncStorage.getItem('firstOpen')
            .then(async (res) => {
                if (res) {
                    setIsFirstOpen(true);
                } else {
                    //await AsyncStorage.setItem('firstOpen', true);
                }
            })
            .catch((e) => console.log(e));
    }

    if (!isAppReady) {
        return (
            <AppLoading
                startAsync={() => {
                    testforFirstOpen();
                }}
                onFinish={() => setIsAppReady(true)}
                onError={console.warn}
            />
        );
    } else {
        return (
            <Provider store={store}>
                <View style={tw.style('flex h-full w-full bg-background')}>
                    <SafeAreaProvider>
                        <StatusBar style="auto" />
                        <NavigationContainer>
                            <Stack.Navigator
                                initialRouteName={
                                    isFirstOpen ? 'firstOpen' : 'start'
                                }
                                screenOptions={{
                                    headerShown: false,
                                }}
                            >
                                <Stack.Screen
                                    name="firstOpen"
                                    component={FirstOpenIndex}
                                    options={{ title: 'Willkommen' }}
                                />
                                <Stack.Screen
                                    name="loginContainer"
                                    component={LoginContainer}
                                    options={{ title: 'Login' }}
                                />
                                <Stack.Screen
                                    name="start"
                                    component={StartScreen}
                                    options={{ title: 'Start' }}
                                />
                                <Stack.Screen
                                    name="authenticatedContainer"
                                    component={Index}
                                />
                            </Stack.Navigator>
                        </NavigationContainer>
                    </SafeAreaProvider>
                </View>
            </Provider>
        );
    }
}
