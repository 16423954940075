import {
    View,
    Text,
    Image,
    TextInput,
    KeyboardAvoidingView,
    TouchableOpacity,
    ScrollView,
} from 'react-native';
import React, { useEffect, useState } from 'react';
import tw from '../../../lib/tailwind';
import Input from '../../../uiKit/input';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';
import { Icon } from 'react-native-elements';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectToken } from '../../../slices/userSlice';

export default function CreatePackage({ navigation, route }) {
    function sub(base, exponent) {
        return (
            <View style={{ flexDirection: 'row' }}>
                <View style={{ alignItems: 'flex-end' }}>
                    <Text style={tw.style('')}>{base}</Text>
                </View>
                <View style={{ alignItems: 'flex-start' }}>
                    <Text style={tw.style('text-xs')}>{exponent}</Text>
                </View>
            </View>
        );
    }

    function calcCosts(volume) {}

    const token = useSelector(selectToken);

    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [price, setPrice] = useState('');

    const [packageVolume, setPackageVolume] = useState('');
    const [packageHeight, setPackageHeight] = useState('');
    const [packageWidth, setPackageWidth] = useState('');
    const [packageDepth, setPackageDepth] = useState('');

    const [buttonDeactivated, setButtonDeactivated] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);

    const [originInformation, setOriginInformation] = useState({
        sender: { gender: 'Herr', firstname: 'Henning', lastname: 'Norden' },
        location: {
            postal: '',
            street: '',
            number: '',
            city: '',
            state: '',
            latitude: 0.0,
            longitude: 0.0,
            shortString: '',
            longString: '',
            placeID: null,
        },
        isInnitSet: true,
    });
    const [destinationInformation, setDestinationInformation] = useState({
        reciever: { gender: '', firstname: '', lastname: '', email: '' },
        location: {
            postal: '',
            street: '',
            number: '',
            city: '',
            state: '',
            latitude: 0.0,
            longitude: 0.0,
            shortString: '',
            longString: '',
            placeID: null,
        },
        isInnitSet: true,
    });

    const titleStyle = tw.style('font-semibold text-lg mb-2');

    useEffect(() => {
        if (route.params?.sender) {
            setOriginInformation(route.params?.sender);
        }
    }, [route.params?.sender]);

    useEffect(() => {
        if (route.params?.destination) {
            setDestinationInformation(route.params?.destination);
        }
    }, [route.params?.destination]);

    useEffect(() => {
        if (
            !destinationInformation.isInnitSet &&
            !originInformation.isInnitSet &&
            packageDepth.trim() !== '' &&
            packageHeight.trim() !== '' &&
            packageWidth.trim() !== '' &&
            name.trim() !== '' &&
            category.trim() !== '' &&
            price.trim() !== '' &&
            parseFloat(price.replace(',', '.')).toFixed(2) * 100 !== 0
        ) {
            setButtonDeactivated(false);
        } else {
            setButtonDeactivated(true);
        }
    }, [
        destinationInformation,
        originInformation,
        packageDepth,
        packageHeight,
        packageWidth,
        name,
        category,
        price,
    ]);

    useEffect(() => {
        var tempDepth = packageDepth !== '' ? parseFloat(packageDepth) : 0.0;
        var tempHeight = packageHeight !== '' ? parseFloat(packageHeight) : 0.0;
        var tempWidth = packageWidth !== '' ? parseFloat(packageWidth) : 0.0;

        setPackageVolume(
            ((tempDepth * tempHeight * tempWidth) / 1000000).toFixed(3)
        );
    }, [packageDepth, packageHeight, packageWidth]);

    function createPackage() {
        setButtonLoading(true);
        axios
            .put(
                process.env.APIURL + 'shipment',
                {
                    name: name,
                    category: 0,
                    length: parseInt(packageDepth),
                    width: parseInt(packageWidth),
                    height: parseInt(packageHeight),
                    recipient: destinationInformation.reciever.email.trim(),
                    origin: originInformation.location,
                    destination: destinationInformation.location,
                    price: parseFloat(price.replace(',', '.')).toFixed(2) * 100,
                    date: Date.now(),
                },
                { headers: { 'Authorization': `Bearer ${token}` } }
            )
            .then((res) => {
                if (res.data) {
                    setButtonLoading(false);
                    navigation.replace('authenticatedContainer', {
                        screen: 'uMission',
                    });
                }
            })
            .catch((error) => {
                setButtonLoading(false);
                if (error.response) {
                    console.log(error.response.data);
                    alert(error.response.data.message);
                }
            });
    }

    return (
        <ScrollView
            style={tw.style('flex-1')}
            contentContainerStyle={tw.style('flex justify-between')}
            bounces={false}
        >
            <View style={tw.style('w-full')}>
                <View style={tw.style('px-6 pt-6')}>
                    <Text style={titleStyle}>Was wird Versand?</Text>
                    <Input
                        placeholder="Gib hier den Names ein (min 10 Zeichen)"
                        value={name}
                        onChangeText={(event) => setName(event)}
                    />
                </View>
                <View style={tw.style(' bg-gray-300 bg-opacity-30 py-3 px-6')}>
                    <View style={tw.style('flex flex-row w-full')}>
                        <View style={tw.style('w-1/3')}>
                            <Image
                                style={tw.style(
                                    'bg-white rounded-lg flex-1 mx-1 h-24'
                                )}
                            />
                        </View>
                        <View style={tw.style('w-2/3')}>
                            <Text style={titleStyle}>Kategorie</Text>
                            <Input
                                placeholder="Kategorie"
                                value={category}
                                onChangeText={(event) => setCategory(event)}
                            />
                            {/*<View
                                style={tw.style(
                                    'flex flex-row items-center'
                                )}
                            >
                                <Text style={tw.style('mb-3 mr-4')}>
                                    {sub('Volumen m', '3')}
                                </Text>
                                <Input
                                    value={packageVolume.toString()}
                                    disabled
                                    width={25}
                                    textCenter
                                    textColor="accent font-bold"
                                />
                                </View>*/}
                        </View>
                    </View>
                    <View style={tw.style('flex flex-row')}>
                        <View style={tw.style('w-1/3 px-1')}>
                            <Text style={titleStyle}>Länge (cm)</Text>
                            <Input
                                keyboardType="numeric"
                                placeholder="Länge (cm)"
                                value={packageWidth}
                                onChangeText={(event) => setPackageWidth(event)}
                            />
                        </View>
                        <View style={tw.style('w-1/3 px-1')}>
                            <Text style={titleStyle}>Breite (cm)</Text>
                            <Input
                                keyboardType="numeric"
                                placeholder="Breite (cm)"
                                value={packageDepth}
                                onChangeText={(event) => setPackageDepth(event)}
                            />
                        </View>
                        <View style={tw.style('w-1/3 px-1')}>
                            <Text style={titleStyle}>Höhe (cm)</Text>
                            <Input
                                keyboardType="numeric"
                                placeholder="Höhe (cm)"
                                value={packageHeight}
                                onChangeText={(event) =>
                                    setPackageHeight(event)
                                }
                            />
                        </View>
                    </View>
                </View>
                <View style={tw.style('px-6 py-3')}>
                    <View>
                        <Text style={titleStyle}>Adresse von</Text>
                        {originInformation.isInnitSet ? (
                            <TouchableOpacity
                                style={tw.style(
                                    'p-4 border border-accent rounded-lg flex flex-row justify-center items-center bg-white'
                                )}
                                onPress={() => navigation.push('setOrigin')}
                            >
                                <Text style={tw.style('mr-1 font-semibold')}>
                                    Sender eintragen
                                </Text>
                                <Icon name="add" color={tw.color('accent')} />
                            </TouchableOpacity>
                        ) : (
                            <View>
                                <Text>
                                    {(
                                        originInformation.sender.gender +
                                        ' ' +
                                        originInformation.sender.firstname +
                                        ' ' +
                                        originInformation.sender.lastname
                                    ).trim()}
                                </Text>
                                <Text>
                                    {originInformation.location.street +
                                        ' ' +
                                        originInformation.location.number}
                                </Text>
                                <Text>
                                    {originInformation.location.postal +
                                        ' ' +
                                        originInformation.location.city}
                                </Text>
                                <Text>{originInformation.location.state}</Text>
                            </View>
                        )}
                    </View>
                </View>
                <View style={tw.style('px-6 py-3')}>
                    <Text style={titleStyle}>Adresse nach</Text>
                    {destinationInformation.isInnitSet ? (
                        <TouchableOpacity
                            style={tw.style(
                                'p-4 border border-accent rounded-lg flex flex-row justify-center items-center bg-white'
                            )}
                            onPress={() => navigation.push('setDestination')}
                        >
                            <Text style={tw.style('mr-1 font-semibold')}>
                                Empfänger eintragen
                            </Text>
                            <Icon name="add" color={tw.color('accent')} />
                        </TouchableOpacity>
                    ) : (
                        <View>
                            <Text>
                                {(
                                    destinationInformation.reciever.gender +
                                    ' ' +
                                    destinationInformation.reciever.firstname +
                                    ' ' +
                                    destinationInformation.reciever.lastname
                                ).trim()}
                            </Text>
                            <Text>
                                {destinationInformation.location.street +
                                    ' ' +
                                    destinationInformation.location.number}
                            </Text>
                            <Text>
                                {destinationInformation.location.postal +
                                    ' ' +
                                    destinationInformation.location.city}
                            </Text>
                            <Text>{destinationInformation.location.state}</Text>
                        </View>
                    )}
                </View>
                <View
                    style={tw.style(
                        'flex flex-row justify-between items-center mx-6 mt-2'
                    )}
                >
                    <Text style={tw.style('text-lg font-semibold ')}>
                        Kosten (zzgl. Vermittlung)
                    </Text>
                    <View
                        style={tw.style(
                            'bg-white rounded-lg shadow-md flex flex-row items-center pr-4'
                        )}
                    >
                        <TextInput
                            style={tw.style('pl-4 py-3 pr-2')}
                            placeholder="0,00"
                            keyboardType="decimal-pad"
                            value={price}
                            onChangeText={(event) => setPrice(event)}
                        />
                        <Text>€</Text>
                    </View>
                </View>
            </View>
            <View
                style={tw.style(
                    'w-full flex flex-row justify-between px-6 mb-3 mt-10'
                )}
            >
                <BackButton onPress={() => navigation.goBack()} />
                <NextButton
                    onPress={() => createPackage()}
                    label="Paket einstellen"
                    disabled={buttonDeactivated}
                    loading={buttonLoading}
                    showIcon={buttonLoading}
                />
            </View>
        </ScrollView>
    );
}
