import {
    View,
    Text,
    TouchableOpacity,
    Image,
    TouchableWithoutFeedback,
    Touchable,
    TouchableHighlight,
} from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import tw from '../../../lib/tailwind';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';
import MapView, { Marker } from 'react-native-maps';
import axios from 'axios';
import Input from '../../../uiKit/input';
import { Icon } from 'react-native-elements';
import { useSelector } from 'react-redux';
import { selectToken } from '../../../slices/userSlice';
import MapViewDirections from 'react-native-maps-directions';

import MarkerBringer from '../../../assets/images/icons/map/Bringer_MapIcon.png';
import MarkerHome from '../../../assets/images/icons/map/Spotlight_Marker.png';

export default function AddRoute({ navigation, route }) {
    const [originLocation, setOriginLocation] = useState(null);
    const [destinationLocation, setDestinationLocation] = useState(null);

    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);

    const [waypoints, setWaypoints] = useState(null);

    const token = useSelector(selectToken);

    const params = route.params;

    const mapRef = useRef();

    useEffect(() => {
        if (params?.originLocation) {
            setOriginLocation(params.originLocation);
        }

        if (params?.destinationLocation) {
            setDestinationLocation(params.destinationLocation);
        }
    }, [params]);

    useEffect(() => {
        if (!originLocation || !destinationLocation) return;
        mapRef.current.fitToSuppliedMarkers(['origin', 'destination'], {
            edgePadding: { top: 75, left: 75, bottom: 75, right: 75 },
        });
    }, [originLocation, destinationLocation]);

    useEffect(() => {
        if (originLocation && destinationLocation && waypoints) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [originLocation, destinationLocation, waypoints]);

    function saveRoute() {
        setButtonLoading(true);
        axios
            .put(
                process.env.APIURL + 'route',

                {
                    origin: originLocation,
                    destination: destinationLocation,
                    directions: waypoints,
                    recurring: false,
                },
                {
                    headers: { 'Authorization': `Bearer ${token}` },
                }
            )
            .then((res) => {
                if (res.data) {
                    setButtonLoading(false);
                    console.log(res.data);
                    /*navigation.replace('authenticatedContainer', {
                        screen: 'uBring',
                        merge: true,
                    }); */

                    navigation.replace('showPackages', {
                        data: {
                            destinationAddress: {
                                latitude: destinationLocation.latitude,
                                longitude: destinationLocation.longitude,
                            },
                            originAddress: {
                                latitude: originLocation.latitude,
                                longitude: originLocation.longitude,
                            },
                        },
                    });
                }
            })
            .catch((e) => alert(e.response.message));
    }

    return (
        <View style={tw.style('flex-1 flex justify-between')}>
            <Text
                style={tw.style(
                    'text-2xl font-bold text-gray-700 text-center mt-7 mb-10'
                )}
            >
                Meine Route
            </Text>
            <View style={tw.style('mx-6')}>
                <View
                    style={tw.style(
                        'flex flex-row justify-between items-center mb-4'
                    )}
                >
                    <Text style={tw.style('font-semibold')}>Adresse VON</Text>
                    <TouchableHighlight
                        onPress={() => navigation.push('setOrigin')}
                        style={tw.style(
                            'w-2/3 flex flex-row justify-end rounded-xl'
                        )}
                    >
                        <Input
                            placeholder="Startort"
                            disabled
                            width="1/1 m-0"
                            value={originLocation?.longString}
                            onPress={() => navigation.push('setOrigin')}
                        />
                    </TouchableHighlight>
                </View>
                <View
                    style={tw.style(
                        'flex flex-row justify-between items-center'
                    )}
                >
                    <Text style={tw.style('font-semibold')}>Adresse NACH</Text>

                    <TouchableHighlight
                        onPress={() => navigation.push('setDestination')}
                        style={tw.style(
                            'w-2/3 flex flex-row justify-end rounded-xl'
                        )}
                    >
                        <Input
                            placeholder="Zielort"
                            width="1/1 m-0"
                            disabled
                            value={destinationLocation?.longString}
                        />
                    </TouchableHighlight>
                </View>
            </View>
            <View
                style={tw.style('w-4/5 bg-gray-300 h-.25 my-3 self-center')}
            />
            <View style={tw.style('w-full flex flex-row justify-evenly mb-3')}>
                <View style={tw.style('flex flex-row items-center')}>
                    <Icon
                        name="schedule"
                        size={32}
                        color={tw.color('accent')}
                    />
                    <Text style={tw.style('ml-1 font-light')}>Zeit</Text>
                </View>
                <View style={tw.style('flex flex-row items-center')}>
                    <Icon
                        name="calendar-today"
                        size={32}
                        color={tw.color('accent')}
                    />
                    <Text style={tw.style('ml-1 font-light')}>Datum</Text>
                </View>
                <View style={tw.style('flex flex-row items-center')}>
                    <Icon
                        name="check-box"
                        size={32}
                        color={tw.color('accent')}
                    />
                    <Text style={tw.style('ml-1 font-light')}>
                        Montag - Freitag
                    </Text>
                </View>
            </View>
            <MapView
                initialRegion={{
                    latitude: 51.37052,
                    longitude: 10.52277,
                    latitudeDelta: 5,
                    longitudeDelta: 5,
                }}
                loadingIndicatorColor={tw.color('primary')}
                loadingBackgroundColor={tw.color('gray-300')}
                provider="google"
                mapType="standard"
                style={tw.style('w-full h-2/5')}
                ref={mapRef}
            >
                {originLocation && destinationLocation && (
                    <MapViewDirections
                        origin={{
                            latitude: originLocation.latitude,
                            longitude: originLocation.longitude,
                        }}
                        destination={{
                            latitude: destinationLocation.latitude,
                            longitude: destinationLocation.longitude,
                        }}
                        apikey={process.env.GOOGLE_API_KEY}
                        strokeColor={tw.color('primary')}
                        strokeWidth={2}
                        mode="DRIVING"
                        onReady={(result) => setWaypoints(result.coordinates)}
                    />
                )}

                {originLocation && (
                    <MapView.Marker
                        icon={MarkerHome}
                        coordinate={{
                            latitude: originLocation.latitude,
                            longitude: originLocation.longitude,
                        }}
                        identifier="origin"
                    >
                        <Image source={MarkerHome} resizeMode="contain" />
                    </MapView.Marker>
                )}
                {destinationLocation && (
                    <MapView.Marker
                        coordinate={{
                            latitude: destinationLocation.latitude,
                            longitude: destinationLocation.longitude,
                        }}
                        identifier="destination"
                        icon={MarkerBringer}
                    >
                        <Image source={MarkerBringer} resizeMode="contain" />
                    </MapView.Marker>
                )}
            </MapView>
            <View style={tw.style('flex flex-row mb-4 px-6')}>
                <BackButton onPress={() => navigation.goBack()} />
                <NextButton
                    label="Speichen"
                    showIcon
                    disabled={buttonDisabled}
                    loading={buttonLoading}
                    onPress={() => saveRoute()}
                />
            </View>
        </View>
    );
}
