import {
    View,
    Text,
    FlatList,
    Dimensions,
    Animated,
    ScrollView,
} from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import BackButton from '../../../uiKit/backButton';
import { useSelector } from 'react-redux';
import { selectToken } from '../../../slices/userSlice';
import axios from 'axios';
import tw from '../../../lib/tailwind';
import PackageCard from '../../../uiKit/packageCard';

export default function PackageDetail({ navigation, route }) {
    const packageIndex = route.params.selectedPackage;

    const scrollX = useRef(new Animated.Value(0)).current;

    const token = useSelector(selectToken);

    const [packages, setPackages] = useState([]);

    const packageCardWidth = (Dimensions.get('window').width / 3) * 2;

    const scrollViewRef = useRef();

    useEffect(() => {
        axios
            .get(process.env.APIURL + 'shipments', {
                headers: { 'Authorization': `Bearer ${token}` },
            })
            .then((res) => {
                if (res.data) {
                    setPackages([
                        { type: 'spacer', data: { id: 'uniqueSpacerId1' } },
                        ...res.data.data,
                        { type: 'spacer', data: { id: 'uniqueSpacerId2' } },
                    ]);
                }
            })
            .catch((e) => console.log(e.response));
    }, [token]);

    return (
        <View style={tw.style('flex-1 flex justify-between')}>
            <Animated.ScrollView
                horizontal
                bounces={false}
                alwaysBounceHorizontal={false}
                alwaysBounceVertical={false}
                showsHorizontalScrollIndicator={false}
                snapToInterval={packageCardWidth}
                decelerationRate={0}
                scrollEventThrottle={16}
                nestedScrollEnabled={true}
                onContentSizeChange={() =>
                    scrollViewRef.current.scrollTo({
                        y: 0,
                        x: packageCardWidth * (packageIndex - 1),
                        animated: true,
                    })
                }
                onScroll={Animated.event(
                    [{ nativeEvent: { contentOffset: { x: scrollX } } }],
                    {
                        useNativeDriver: true,
                    }
                )}
                ref={scrollViewRef}
            >
                {packages.map((item, index) => {
                    const inputRange = [
                        (index - 3) * packageCardWidth,
                        (index - 2) * packageCardWidth,
                        (index - 1) * packageCardWidth,
                        index * packageCardWidth,
                        (index + 1) * packageCardWidth,
                    ];

                    const translateY = scrollX.interpolate({
                        inputRange,
                        outputRange: [0, 0, 30, 0, 0],
                    });

                    const opacity = scrollX.interpolate({
                        inputRange,
                        outputRange: [0, 0, 1, 0, 0],
                    });

                    return (
                        <PackageCard
                            id={item.data.id}
                            data={{ ...item }}
                            containerWidth={packageCardWidth}
                            translateY={translateY}
                            opacity={opacity}
                            key={item.data.id}
                            navigation={navigation}
                        />
                    );
                })}
            </Animated.ScrollView>
        </View>
    );
}
