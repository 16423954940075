import { View, Text } from 'react-native';
import React, { useEffect, useState } from 'react';
import MapView, { Circle, Marker } from 'react-native-maps';
import MarkerHome from '../../../assets/images/icons/map/Spotlight_Marker.png';
import MarkerSender from '../../../assets/images/icons/map/Sender_MapIcon.png';
import MarkerBringer from '../../../assets/images/icons/map/Bringer_MapIcon.png';
import tw from '../../../lib/tailwind';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectToken } from '../../../slices/userSlice';
import { Image } from 'react-native';
import BackButton from '../../../uiKit/backButton';
import MapViewDirections from 'react-native-maps-directions';

export default function ShowPackages({ route, navigation }) {
    const data = route.params.data;

    const [packages, setPackages] = useState([]);

    const token = useSelector(selectToken);

    useEffect(() => {
        axios
            .post(
                process.env.APIURL + 'shipments/radius',
                {
                    latitude: parseFloat(data.originAddress.latitude),
                    longitude: parseFloat(data.originAddress.longitude),
                },
                {
                    headers: { 'Authorization': `Bearer ${token}` },
                }
            )
            .then((res) => {
                if (res.data) {
                    setPackages(res.data);
                }
            })
            .catch((e) => console.log(e));
    }, [token, data]);

    return (
        <>
            <MapView
                initialRegion={{
                    latitude: parseFloat(data.originAddress.latitude),
                    longitude: parseFloat(data.originAddress.longitude),
                    latitudeDelta: 0.5,
                    longitudeDelta: 0.5,
                }}
                loadingIndicatorColor={tw.color('primary')}
                loadingBackgroundColor={tw.color('gray-300')}
                provider="google"
                mapType="standard"
                style={tw.style('flex-1')}
            >
                {packages.map((data, index) => {
                    var formatedPrice = data.price / 100;

                    formatedPrice =
                        formatedPrice.toFixed(2).replace('.', ',') + ' €';

                    return (
                        <MapView.Marker
                            coordinate={{
                                latitude: parseFloat(data.latitude),
                                longitude: parseFloat(data.longitude),
                            }}
                            key={index}
                            onPress={() =>
                                navigation.push('packageInfo', { data })
                            }
                            icon={MarkerSender}
                        >
                            <View
                                style={tw.style(
                                    'flex justify-center items-center'
                                )}
                            >
                                <View
                                    style={tw.style(
                                        'bg-white rounded-lg p-3 shadow-md mb-1.5'
                                    )}
                                >
                                    <Text>{formatedPrice}</Text>
                                </View>
                                <Image
                                    source={MarkerSender}
                                    resizeMode="contain"
                                />
                            </View>
                        </MapView.Marker>
                    );
                })}
                <MapViewDirections
                    origin={{
                        latitude: parseFloat(data.originAddress.latitude),
                        longitude: parseFloat(data.originAddress.longitude),
                    }}
                    destination={{
                        latitude: parseFloat(data.destinationAddress.latitude),
                        longitude: parseFloat(
                            data.destinationAddress.longitude
                        ),
                    }}
                    apikey={process.env.GOOGLE_API_KEY}
                    strokeColor={tw.color('primary')}
                    strokeWidth={2}
                    mode="DRIVING"
                />
                <MapView.Marker
                    coordinate={{
                        latitude: parseFloat(data.originAddress.latitude),
                        longitude: parseFloat(data.originAddress.longitude),
                    }}
                    icon={MarkerHome}
                >
                    <Image source={MarkerHome} resizeMode="contain" />
                </MapView.Marker>
                <MapView.Marker
                    coordinate={{
                        latitude: parseFloat(data.destinationAddress.latitude),
                        longitude: parseFloat(
                            data.destinationAddress.longitude
                        ),
                    }}
                    icon={MarkerBringer}
                >
                    <Image source={MarkerBringer} resizeMode="contain" />
                </MapView.Marker>
            </MapView>
            <BackButton
                onPress={() => navigation.goBack()}
                extraStyles={'absolute bottom-5 right-5'}
            />
        </>
    );
}
